import { externalConstraint } from '@flatfile/plugin-constraints'
import { recordkeeperListener } from './listeners'
import { isEmpty } from 'lodash'

export const bdParticipantListeners = (listener) => {
  listener.use(
    externalConstraint('recordKeeper', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  recordkeeperListener(listener, 'participant-record')

  listener.use(
    externalConstraint('tpa', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planSponsor', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planName', (value, key, { config, record }) => {
      if (value && new RegExp(config.noSlashRegex.regex).test(value)) {
        record.addError(key, config.noSlashRegex.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('planEin', (value, key, { config, record }) => {
      if (value && !new RegExp(config.invalidEINFormat.regex).test(value)) {
        record.addError(key, config.invalidEINFormat.error)
      }
      if (value && !new RegExp(config.invalidEIN.regex).test(value)) {
        record.addError(key, config.invalidEIN.error)
      }
    })
  )

  listener.use(
    externalConstraint('planNumber', (value, key, { config, record }) => {
      if (value && new RegExp(config.invalidCharacters.regex).test(value)) {
        record.addError(key, config.invalidCharacters.error)
      }
      if (value && !new RegExp(config.mustBeThreeDigits.regex).test(value)) {
        record.addError(key, config.mustBeThreeDigits.error)
      }
    })
  )

  listener.use(
    externalConstraint('firstName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (value && new RegExp(config.checkNamePrefix.regex, 'i').test(value)) {
        record.addError(key, config.checkNamePrefix.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('middleName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('lastName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('addressLine1', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
    })
  )

  listener.use(
    externalConstraint('addressLine2', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('city', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('phone', (value, key, { config, record }) => {
      if (value && !new RegExp(config.phoneNumber.regex).test(value)) {
        record.addError(key, config.phoneNumber.error)
      }
    })
  )

  listener.use(
    externalConstraint('ssn', (value, key, { config, record }) => {
      if (value && new RegExp(config.ssnValidityCheck.regex).test(value)) {
        record.addError(key, config.ssnValidityCheck.error)
      }
    })
  )

  listener.use(
    externalConstraint('emailAddress', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('recurringPayment', (value, key, { config, record }) => {
      if (value && !new RegExp(config.recurringPayment.regex).test(value)) {
        record.addError(key, config.recurringPayment.error)
      }
    })
  )

  listener.use(
    externalConstraint('frequency', (value, key, { config, record }) => {
      if (value && !new RegExp(config.frequency.regex).test(value)) {
        record.addError(key, config.frequency.error)
      }
      if (record && record.get('recurringPayment') === 'Y' && isEmpty(value)) {
        record.addError(key, `Required only if Reccuring Payment = 'Y'`)
      }
    })
  )

  listener.use(
    externalConstraint('transactionType', (value, key, { config, record }) => {
      if (value && !new RegExp(config.transactionType.regex, 'i').test(value)) {
        record.addError(key, config.transactionType.error)
      }
    })
  )

  listener.use(
    externalConstraint('amount', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint(
      'tpaBenefitDistributionProcessingFee',
      (value, key, { config, record }) => {
        if (
          value &&
          new RegExp(config.createMaxCharValidator.regex).test(value)
        ) {
          record.addError(key, config.createMaxCharValidator.error)
        }
      }
    )
  )

  listener.use(
    externalConstraint(
      'paymentInstructions',
      (value, key, { config, record }) => {
        if (
          value &&
          !new RegExp(config.paymentInstructions.regex, 'i').test(value)
        ) {
          record.addError(key, config.paymentInstructions.error)
        }
      }
    )
  )

  listener.use(
    externalConstraint(
      'inspiraBenefitDisbursementProcessingFee',
      (value, key, { config, record }) => {
        if (
          value &&
          new RegExp(config.createMaxCharValidator.regex).test(value)
        ) {
          record.addError(key, config.createMaxCharValidator.error)
        }
      }
    )
  )

  listener.use(
    externalConstraint(
      'distributionAmount',
      (value, key, { config, record }) => {
        if (
          value &&
          new RegExp(config.createMaxCharValidator.regex).test(value)
        ) {
          record.addError(key, config.createMaxCharValidator.error)
        }
      }
    )
  )
  listener.use(
    externalConstraint('wireAch', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
  listener.use(
    externalConstraint('abaRouting', (value, key, { config, record }) => {
      if (value && !new RegExp(config.abaRouting.regex, 'i').test(value)) {
        record.addError(key, config.abaRouting.error)
      }
    })
  )
  listener.use(
    externalConstraint('wireAch2', (value, key, { config, record }) => {
      if (value && !new RegExp(config.wireAch2.regex, 'i').test(value)) {
        record.addError(key, config.wireAch2.error)
      }
    })
  )
  listener.use(
    externalConstraint('wireAch3', (value, key, { config, record }) => {
      if (value && !new RegExp(config.wireAch3.regex, 'i').test(value)) {
        record.addError(key, config.wireAch3.error)
      }
    })
  )
  listener.use(
    externalConstraint('ffcName', (value, key, { config, record }) => {
      if (value && !new RegExp(config.ffcName.regex, 'i').test(value)) {
        record.addError(key, config.ffcName.error)
      }
    })
  )
  listener.use(
    externalConstraint('ffcNumber', (value, key, { config, record }) => {
      if (value && !new RegExp(config.ffcNumber.regex, 'i').test(value)) {
        record.addError(key, config.ffcNumber.error)
      }
    })
  )
  listener.use(
    externalConstraint('accountType', (value, key, { config, record }) => {
      if (value && !new RegExp(config.accountType.regex, 'i').test(value)) {
        record.addError(key, config.accountType.error)
      }
    })
  )
  listener.use(
    externalConstraint('checkPayableInfo', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
  listener.use(
    externalConstraint('accountNumber', (value, key, { config, record }) => {
      if (value && !new RegExp(config.accountNumber.regex, 'i').test(value)) {
        record.addError(key, config.accountNumber.error)
      }
    })
  )
  listener.use(
    externalConstraint('addressForCheck', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
  listener.use(
    externalConstraint('cityForCheck', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
  listener.use(
    externalConstraint('stateForCheck', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
  listener.use(
    externalConstraint('zipForCheck', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )
}
