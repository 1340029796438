import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { selectors as apiSelectors } from 'lp-redux-api'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import { selectors as profileSelectors } from '../../profile/reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useHistory, Redirect } from 'react-router-dom'
import { flashErrorMessage } from 'redux-flash'
import {
  Spinner,
  FullPageSpinner,
  Tooltip,
  Notification,
  ComponentFailure,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { Path, isSearchPaymentEnabled, CREDIT_CARD_OPTION } from 'config/portal'
import {
  TOAST_ERROR_CONTACT_SUPPORT_EMAIL,
  TOAST_ERROR_CREDIT_CARD,
  TOAST_ERROR_SEARCH_ORDER_PROGRESS,
  SEARCH_ORDER_WITH_PAYMENT_SUCCESS_MESSAGE,
  SEARCH_ORDER_SUCCESS_MESSAGE,
} from 'config/notifications'
import { US_COUNTRY_VALUE } from 'config/locations'
import { SearchTypeID } from 'config/search-services'
import { ProductType } from 'config/products'
import * as Types from 'types'
import { ConfirmSubmissionForm } from '../forms'
import { Icon as InfoIcon } from 'images/info.svg'
import { isEmpty } from 'lodash'
import { formatCurrency } from 'utils'
import * as globalActions from '../../actions'
import * as productActions from '../../products/actions'
import * as profileActions from '../../profile/actions'

const propTypes = {
  currentUser: Types.user.isRequired,
  updateSearchOrder: PropTypes.func.isRequired,
  serviceAgreementId: PropTypes.string.isRequired,
  isUpdatingSearchOrder: PropTypes.bool.isRequired,
  paymentMethod: Types.paymentMethod,
  fetchPaymentMethod: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  newSearchFlowData: Types.newSearchFlowData,
  showSuccessModal: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  isDeletingPaymentMethod: PropTypes.bool.isRequired,
  saveCompletePaymentDetails: PropTypes.func.isRequired,
  setShouldTriggerSearchesFetch: PropTypes.func.isRequired,
  shouldTriggerPaymentInfoFetch: PropTypes.bool.isRequired,
  setShouldTriggerPaymentInfoFetch: PropTypes.func.isRequired,
}
const defaultProps = {
  paymentMethod: null,
  newSearchFlowData: null,
}

function ConfirmSubmissionView({
  currentUser,
  updateSearchOrder,
  serviceAgreementId,
  flashErrorMessageHandler,
  isUpdatingSearchOrder,
  newSearchFlowData,
  paymentMethod,
  fetchPaymentMethod,
  showSuccessModal,
  deletePaymentMethod,
  isDeletingPaymentMethod,
  saveCompletePaymentDetails,
  setShouldTriggerSearchesFetch,
  shouldTriggerPaymentInfoFetch,
  setShouldTriggerPaymentInfoFetch,
}) {
  const history = useHistory()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNewPayment, setIsNewPayment] = useState(Boolean)
  const paymentEnabled = isSearchPaymentEnabled()

  useEffect(() => {
    if (!paymentEnabled) return

    async function getPaymentMethod() {
      try {
        setIsLoading(true)
        await fetchPaymentMethod()
        setIsLoading(false)
      } catch {
        setError(true)
      }
    }

    if (!paymentMethod || shouldTriggerPaymentInfoFetch) {
      getPaymentMethod()
      setShouldTriggerPaymentInfoFetch(false)
    }
  }, [paymentMethod, paymentEnabled, shouldTriggerPaymentInfoFetch])
  const isMockedPayment = paymentMethod && paymentMethod[0]?.mock == ''

  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />
  if (isEmpty(newSearchFlowData.dmfPermissible))
    return <Redirect to={Path.PERMISSIBLE_USE} />

  const {
    searchType,
    turnaroundTime,
    recordsData,
    dmfPermissible,
    dmfPermissibleAdditional,
    glbaPermissible,
    dppaPermissible,
    finalMailing,
    numberofMailings,
    mailingMethod,
    total,
  } = newSearchFlowData

  if (
    newSearchFlowData.searchType !== SearchTypeID.FIDUCIARY_SEARCH &&
    newSearchFlowData.finalMailing
  ) {
    newSearchFlowData.finalMailing = ''
  } else if (
    newSearchFlowData.mailingMethod &&
    newSearchFlowData.searchType !== SearchTypeID.CONFIRMED_SEARCH
  ) {
    newSearchFlowData.mailingMethod = ''
  } else if (
    newSearchFlowData.numberofMailings &&
    newSearchFlowData.searchType !== SearchTypeID.CONFIRMED_SEARCH
  ) {
    newSearchFlowData.numberofMailings = ''
  }

  const handleDeletePayment = async () => {
    try {
      await deletePaymentMethod()
      setIsNewPayment(true)
    } catch (e) {
      flashErrorMessage(TOAST_ERROR_CONTACT_SUPPORT_EMAIL)
    }
  }

  const handleSubmit = async (values) => {
    const searchOrderData = {
      contactID: currentUser?.contactID,
      serviceAgreementId,
      searchType: searchType,
      numberOfSearches: recordsData.length,
      dmfPermissible: dmfPermissibleAdditional || dmfPermissible,
      glbaPermissible,
      dppaPermissible,
      requestedCompanyID: currentUser?.accountID,
      searchRequests: [...recordsData],
      numberofMailings,
      mailingMethod,
      finalMailing,
    }

    const hasBalance = total > 0

    const hasBalanceAndPaymentEnabled = hasBalance && paymentEnabled

    if (paymentMethod.zipCode) {
      setIsNewPayment(false)
    } else if (!paymentMethod.zipCode) {
      setIsNewPayment(true)
    }

    const paymentDetails =
      hasBalanceAndPaymentEnabled && isNewPayment
        ? {
            firstName: values.paymentDetails.firstName,
            lastName: values.paymentDetails.lastName,
            country: values.paymentDetails.country,
            state: values.paymentDetails.state,
            city: values.paymentDetails.city,
            address1: values.paymentDetails.address1,
            address2: values.paymentDetails.address2,
            zipCode: values.paymentDetails.zip,
            paymentToken: values.paymentDetails.cardData.paymentToken,
            fraudId: values.paymentDetails.cardData.fraud,
            creditCardLastFour: values.paymentDetails.cardData.last4Digits,
            cardExpMonth: values.paymentDetails.cardData.exp.split('/')[0],
            cardExpYear: values.paymentDetails.cardData.exp.split('/')[1],
            cardType: values.paymentDetails.cardData.ccType,
            cardStatus: 'Active',
            cardLabel: values.paymentDetails.cardData.cardLabel,
            phone: null,
            email: null,
          }
        : null

    try {
      if (hasBalanceAndPaymentEnabled) {
        if (isNewPayment) {
          const paymentResponse = await saveCompletePaymentDetails({
            paymentDetails,
          })
          if (paymentResponse.IsSuccess == false) {
            throw new Error('Credit card cannot be processed')
          }
        }
        const searchResponse = await updateSearchOrder(searchOrderData)
        if (
          searchResponse.IsSuccess == false &&
          searchResponse.Message == 'Search order creation is in progress'
        ) {
          throw new Error('Search order creation is in progress')
        }
        setShouldTriggerPaymentInfoFetch(true)
      } else {
        await updateSearchOrder(searchOrderData)
      }
      history.push({
        pathname: Path.SEARCH_HISTORY,
        state: {
          ignorePrompt: true, // No need to warn the user about leaving the flow
        },
      })
      setShouldTriggerSearchesFetch(true)
      showSuccessModal({
        title: 'Success!',
        message: hasBalanceAndPaymentEnabled
          ? SEARCH_ORDER_WITH_PAYMENT_SUCCESS_MESSAGE
          : SEARCH_ORDER_SUCCESS_MESSAGE,
      })
    } catch (e) {
      if (e == 'Error: Credit card cannot be processed') {
        flashErrorMessageHandler(TOAST_ERROR_CREDIT_CARD)
      } else if (e == 'Error: Search order creation is in progress') {
        flashErrorMessageHandler(TOAST_ERROR_SEARCH_ORDER_PROGRESS)
      } else {
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT_EMAIL)
      }
    }
  }

  if (error) return <ComponentFailure />

  if (isLoading)
    return (
      <div className="search-details card full-height">
        <div className="form-masthead">
          <h2>Lastly, review your submission and accept the disclosures</h2>
        </div>
        <Spinner />
      </div>
    )

  return (
    <div className="card full-height">
      {(isUpdatingSearchOrder || isDeletingPaymentMethod) && (
        <FullPageSpinner showBackground />
      )}
      <div className="form-masthead">
        <h2>Lastly, review your submission and accept the disclosures</h2>
      </div>
      <div className="form-header">
        <h3 className="flow-section-headers">Submission details</h3>
      </div>
      <section className="confirm-order-details">
        <dl>
          <div>
            <dt>Type</dt>
            <dd>{searchType}</dd>
          </div>
          <div>
            <dt>Number of records searched</dt>
            <dd>{recordsData?.length}</dd>
          </div>
          <div>
            <dt>
              Total
              <Tooltip
                content="Total cost of this submission."
                ariaLabel="Total cost of this submission."
              >
                <InfoIcon className="icon-info" />
              </Tooltip>
            </dt>
            <dd>{formatCurrency(total)}</dd>
          </div>
          <div>
            <dt>Estimated turnaround time</dt>
            <dd>
              <Notification
                type={NotificationVariant.INFORMATIONAL}
                isBadge={true}
              >
                {turnaroundTime}
              </Notification>
            </dd>
          </div>
        </dl>
      </section>
      <ConfirmSubmissionForm
        initialValues={{
          confirm: false,
          paymentDetails: {
            paymentType: CREDIT_CARD_OPTION.value,
            firstName: '',
            lastName: '',
            cardData: '',
            country: US_COUNTRY_VALUE,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
          },
          confirmPayment: false,
        }}
        onSubmit={(params) => handleSubmit(params)}
        paymentEnabled={paymentEnabled}
        currentUser={currentUser}
        paymentMethod={paymentMethod}
        onConfirmDeletePayment={handleDeletePayment}
        isDeletingPaymentMethod={isDeletingPaymentMethod}
        hasBalance={total > 0}
        isMockedPayment={isMockedPayment}
      />
    </div>
  )
}

ConfirmSubmissionView.propTypes = propTypes
ConfirmSubmissionView.defaultProps = defaultProps

function mapStateToProps(state) {
  const subscribedProducts = globalSelectors.orderedSubscribedProducts(state)
  const searchServiceProduct = subscribedProducts.find(
    (product) => product.type === ProductType.SEARCH_SERVICES
  )
  return {
    currentUser: globalSelectors.currentUser(state),
    isUpdatingSearchOrder: apiSelectors.isLoading(
      state,
      apiActions.updateSearchOrder
    ),
    newSearchFlowData: selectors.newSearchFlowData(state),
    serviceAgreementId: searchServiceProduct.agreementId,
    paymentMethod: profileSelectors.paymentMethod(state),
    completePaymentDetails: profileSelectors.completePaymentDetails(state),
    shouldTriggerPaymentInfoFetch:
      profileSelectors.shouldTriggerPaymentInfoFetch(state),
    isDeletingPaymentMethod: apiSelectors.isLoading(
      state,
      apiActions.deletePaymentMethod
    ),
  }
}

const mapDispatchToProps = {
  updateSearchOrder: apiActions.updateSearchOrder,
  fetchPaymentMethod: apiActions.fetchPaymentMethod,
  flashErrorMessageHandler: flashErrorMessage,
  showSuccessModal: globalActions.showSuccessModal,
  deletePaymentMethod: apiActions.deletePaymentMethod,
  saveCompletePaymentDetails: apiActions.saveCompletePaymentDetails,
  setShouldTriggerSearchesFetch: productActions.setShouldTriggerSearchesFetch,
  setShouldTriggerPaymentInfoFetch:
    profileActions.setShouldTriggerPaymentInfoFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmSubmissionView
)
