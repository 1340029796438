import { getFlatfileConfig, RecordTypes } from 'flatfile-config'
import { validateTaxId, findUSState, parseDateFromString } from 'utils'
import { format, isValid, isBefore } from 'date-fns'

const numericRegex = /[0-9]/g
const invalidPostalCodes = [
  '01234',
  '34567',
  '56789',
  '67890',
  '00000',
  '11111',
  '22222',
  '33333',
  '55555',
  '66666',
  '77777',
  '99999',
]

const isNumeric = (value) => value.replace(/\D/g, '') === value
const isNa = (value) => {
  const isString = typeof value === 'string'
  const formattedValue = value.toUpperCase()
  const matchesNa = formattedValue === 'N/A'
  return isString && matchesNa
}

const flatFileConfig = getFlatfileConfig(RecordTypes.SEARCH)
const fieldKeys = flatFileConfig.fields.map((field) => field.key)

function formatAndValidateSearchRecord(originalRecord) {
  /* convert string values to an object with 'value' -- necessary if values get modified or if a custom error message is provided (under 'info')
   if the record is missing any fields outlined in config, set its initial value as an empty string
  e.g., { firstName: "Jane", lastName: "Doe" } -> { firstName: { value: "Jane" }, lastName: { value: "Doe" }, middleName: {value: "" } } */
  let convertedRecord = fieldKeys.reduce((record, field) => {
    record[field] = { value: record[field]?.trim() ?? '' }
    return record
  }, originalRecord)

  const formattedRecord = formatRecord(convertedRecord)
  const formattedAndValidatedRecord = validateRecord(formattedRecord)

  return formattedAndValidatedRecord
}

const formatRecord = (record) => {
  let { ssn, state, zip, dob, dod, planEin, planNumber } = record

  if (ssn.value) {
    const firstLetter = ssn.value.match(/(([\d]\D*?){9})([a-zA-Z])/)?.[3] || ''
    const sanitizedSsn = ssn.value.match(numericRegex)?.join('')
    // note: this approach concatenates numbers first: e.g., 111-22-3333A000 -> 111-22-3333000A
    if (sanitizedSsn) {
      ssn.value =
        sanitizedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') + firstLetter
    }
  }

  if (state.value) {
    const matchingState = findUSState(state.value)
    if (matchingState) {
      state.value = matchingState.value
    } else {
      state.info = [
        {
          message: 'Invalid US State abbreviation',
          level: 'error',
        },
      ]
    }
  }

  if (zip.value) {
    zip.value = zip.value.replaceAll(/[^a-zA-Z0-9- ]/g, '')
    const sanitizedZip = zip.value.match(numericRegex)?.join('')
    if (sanitizedZip) {
      zip.value = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zip.value = '0' + zip.value
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zip.value = '00' + zip.value
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zip.value = '000' + zip.value
      }
    }
    if (sanitizedZip.length > 5) {
      zip.value = zip.value.substring(0, 5) + '-' + zip.value.substring(5)
    }
  }

  if (dob.value) {
    const dobDate = isNumeric(dob.value) ? null : parseDateFromString(dob.value)
    let formattedDob = dob.value

    if (dobDate) {
      formattedDob = format(dobDate, 'M/d/yyyy')
    } else {
      const sanitizedDob = dob.value.match(/[0-9\\/]/g)?.join('')
      if (sanitizedDob && sanitizedDob !== '/') {
        formattedDob = sanitizedDob
      } else {
        formattedDob = isNa(dob.value) ? dob.value.toUpperCase() : dob.value
      }
    }
    dob.value = formattedDob
  }

  if (dod.value) {
    const dodDate = isNumeric(dod.value) ? null : parseDateFromString(dod.value)
    let formattedDod = dod.value

    if (dodDate) {
      formattedDod = format(dodDate, 'M/d/yyyy')
    } else {
      const sanitizedDod = dod.value.match(/[0-9\\/]/g)?.join('')
      if (sanitizedDod && sanitizedDod !== '/') {
        formattedDod = sanitizedDod
      } else {
        formattedDod = isNa(dod.value) ? dod.value.toUpperCase() : dod.value
      }
    }
    dod.value = formattedDod
  }
  if (planEin.value) {
    const sanitizedPlanEin = planEin.value.match(numericRegex)?.join('')
    // formats EIN to XX-XXXXXXX
    if (sanitizedPlanEin) {
      planEin.value = sanitizedPlanEin.replace(/(\d{2})(\d+)/, '$1-$2')
    }
  }
  if (planNumber.value) {
    const sanitizedPlanNumber = planNumber.value.match(numericRegex)?.join('')
    if (sanitizedPlanNumber) {
      planNumber.value = sanitizedPlanNumber.replace(/(\d{3})/, '$1')
    }
  }

  return record
}

const validateRecord = (record) => {
  let { ssn, dob, dod, zip } = record

  if (ssn.value) {
    if (!validateTaxId(ssn.value)) {
      ssn.info = [
        {
          message: 'Given SSN is not valid',
          level: 'error',
        },
      ]
    }
  }

  if (dob.value) {
    const dobDate = isNumeric(dob.value) ? null : new Date(dob.value)

    if (isValid(dobDate)) {
      const currentDate = new Date()
      const maxDate = new Date(currentDate)
      maxDate.setFullYear(currentDate.getFullYear() - 100)
      const minDate = new Date(currentDate)
      minDate.setFullYear(currentDate.getFullYear() - 10)
      if (dobDate > minDate) {
        dob.info = [{ message: 'Must be over 10 years.', level: 'error' }]
      } else {
        if (isBefore(dobDate, maxDate)) {
          dob.info = [{ message: 'Must be under 100 years.', level: 'error' }]
        }
      }
    } else {
      if (!isNa(dob.value)) {
        const dateRe = new RegExp('^[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}$')
        dob.info = dateRe.test(dob.value)
          ? [
              {
                message: 'Must be a valid date', // e.g., "01/32/2000"
                level: 'error',
              },
            ]
          : [
              {
                message:
                  'Format must be single-digit M if applicable, otherwise MM/DD/YYYY',
                level: 'error',
              },
            ]
      }
    }
  }

  if (dod.value) {
    const dodDate = isNumeric(dod.value) ? null : new Date(dod.value)

    if (isValid(dodDate)) {
      if (dodDate > new Date()) {
        dod.info = [{ message: 'Cannot be in the future', level: 'error' }]
      } else {
        if (isBefore(dodDate, new Date(0, 0, 1))) {
          dod.info = [
            { message: 'Must be more recent than 1900', level: 'error' },
          ]
        }
      }
    } else {
      if (!isNa(dod.value)) {
        const dateRe = new RegExp('^[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}$')
        dod.info = dateRe.test(dod.value)
          ? [
              {
                message: 'Must be a valid date', // e.g., "01/32/2000"
                level: 'error',
              },
            ]
          : [
              {
                message:
                  'Format must be single-digit M if applicable, otherwise MM/DD/YYYY',
                level: 'error',
              },
            ]
      }
    }
  }

  if (zip.value) {
    const zipRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
    const postalCode = zip.value.substring(0, 5)
    if (!zipRe.test(zip.value)) {
      zip.info = [
        {
          message: 'Zip must have format: XXXXX or XXXXX-XXXX',
          level: 'error',
        },
      ]
    } else if (invalidPostalCodes.includes(postalCode)) {
      zip.info = [
        {
          message: 'Please enter a valid Zip Code',
          level: 'error',
        },
      ]
    }
  }

  return record
}

export default formatAndValidateSearchRecord
