import { format, isValid, isBefore } from 'date-fns'
import { toNumber } from 'lodash'
import {
  formatCurrency,
  validateTaxId,
  parseDateFromString,
  findCountry,
  findUSState,
  convertToLowercase,
  convertToUppercase,
} from 'utils'
import * as yup from 'yup'
import { getFlatfileConfig, RecordTypes } from 'flatfile-config'
import { US_COUNTRY_VALUE } from 'config/locations'
import {
  BD_TRANSACTION_TYPES,
  BD_PAYMENT_INSTRUCTIONS,
} from 'config/benefit-distributions'
import { invalidPostalCodes } from '../config/products'

const numericRegex = /[0-9]/g
const currencyRegex = /[0-9-.]/g

const requiredError = [{ message: 'Required.', level: 'error' }]

// returns false if a value has invalid currency formatting (e.g., "10,00", "20.000", "30.00.00", "40.00,200")
// or if it doesn't contain any number (e.g., "apple", "")
const isValidAmount = (value) => {
  const sanitized = value.replaceAll(/[^0-9.,]/g, '')
  if (!sanitized) return false
  const currencyParts = sanitized.split('.')
  // If a decimal point exists, ensure that it only occurs at the end of the string and is followed by 1 or 2 digits
  if (
    currencyParts.length > 2 ||
    (currencyParts.length === 2 && !/^[0-9]{1,2}$/.test(currencyParts[1]))
  )
    return false
  // Check the value provided for proper separation (i.e., ignore the decimal)
  const [first, ...rest] = currencyParts[0].split(',')
  if (rest.length === 0) return true
  return first.length <= 3 && rest.every((v) => v.length === 3)
}

const removeCurrencySymbolAndParse = (value) => {
  // Regular expression to remove any non-digit characters except '.', '-'
  const sanitizedValue = value.replace(/[^\d.]/g, '')
  return parseFloat(sanitizedValue) || 0
}

const formatAndValidateCurrency = (input, ignoreFormatErrorMessage = false) => {
  if (ignoreFormatErrorMessage) input = input.replace(/[^$\d,.]/g, '')
  const sanitizedInput = input.match(currencyRegex)?.join('')
  const amount = toNumber(sanitizedInput)
  if (isNaN(amount) || !isValidAmount(input)) {
    return {
      value: input,
      info: ignoreFormatErrorMessage
        ? []
        : [
            {
              message: 'Format must be: $X,XXX.XX',
              level: 'error',
            },
          ],
    }
  }
  return {
    value: formatCurrency(amount),
    info:
      amount < 0
        ? [
            {
              message: 'Amount cannot be negative',
              level: 'error',
            },
          ]
        : [],
  }
}

const flatfileConfig = getFlatfileConfig(RecordTypes.BD_PARTICIPANT)
const fieldKeys = flatfileConfig.fields.map((field) => field.key)

function formatAndValidateBDRecord(originalRecord) {
  let convertedRecord = fieldKeys.reduce((record, field) => {
    record[field] = { value: record[field]?.trim() ?? '' }
    return record
  }, originalRecord)

  const formattedRecord = formatRecord(convertedRecord)
  const formattedAndValidatedRecord = validateRecord(formattedRecord)

  return formattedAndValidatedRecord
}

const formatRecord = (record) => {
  let {
    planEin,
    planNumber,
    zipCode,
    dob,
    ssn,
    state,
    countryCode,
    recurringPayment,
    frequency,
    amount,
    transactionType,
    paymentInstructions,
    tpaBenefitDistributionProcessingFee,
    inspiraBenefitDisbursementProcessingFee,
    stateForCheck,
    zipForCheck,
  } = record

  if (planEin.value) {
    const sanitizedPlanEin = planEin.value.match(numericRegex)?.join('')
    // formats EIN to XX-XXXXXXX
    if (sanitizedPlanEin) {
      planEin.value = sanitizedPlanEin.replace(/(\d{2})(\d+)/, '$1-$2')
    }
  }
  if (planNumber.value) {
    const sanitizedPlanNumber = planNumber.value.match(numericRegex)?.join('')
    if (sanitizedPlanNumber) {
      planNumber.value = sanitizedPlanNumber.replace(/(\d{3})/, '$1')
    }
  }
  if (zipCode.value) {
    zipCode.value = zipCode.value.replaceAll(/[^a-zA-Z0-9- ]/g, '')
    const sanitizedZip = zipCode.value.match(numericRegex)?.join('')
    if (sanitizedZip) {
      zipCode.value = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zipCode.value = '0' + zipCode.value
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zipCode.value = '00' + zipCode.value
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zipCode.value = '000' + zipCode.value
      }
    }
    if (sanitizedZip.length > 5) {
      zipCode.value =
        zipCode.value.substring(0, 5) + '-' + zipCode.value.substring(5)
    }
  }
  if (dob.value) {
    const dobDate = parseDateFromString(dob.value.trim())
    let formattedDob = dob.value

    if (dobDate) {
      formattedDob = format(dobDate, 'yyyy/MM/dd')
    } else {
      const sanitizedDob = dob.value.match(numericRegex)?.join('')
      if (sanitizedDob) {
        formattedDob = sanitizedDob.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
      }
    }
    dob.value = formattedDob
  }
  if (ssn.value) {
    const firstLetter = ssn.value.match(/(([\d]\D*?){9})([a-zA-Z])/)?.[3] || ''
    const sanitizedSsn = ssn.value.match(numericRegex)?.join('')
    // note: this approach concatenates numbers first: e.g., 111-22-3333A000 -> 111-22-3333000A
    if (sanitizedSsn) {
      ssn.value =
        sanitizedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') + firstLetter
    }
  }

  if (countryCode.value) {
    const matchingCountry = findCountry(countryCode.value)

    if (matchingCountry) {
      countryCode.value = matchingCountry.value
    } else {
      countryCode.info = [
        {
          message: 'Must be a valid country name or country code',
          level: 'error',
        },
      ]
    }
  }

  if (state.value && countryCode.value === US_COUNTRY_VALUE) {
    const matchingState = findUSState(state.value)
    if (matchingState) {
      state.value = matchingState.value
    } else {
      state.info = [{ message: 'Must be a valid US state', level: 'error' }]
    }
  }

  if (recurringPayment.value) {
    recurringPayment.value = convertToUppercase(recurringPayment.value)
  }
  if (frequency.value) {
    frequency.value = convertToUppercase(frequency.value)
  }
  if (amount.value) {
    record.amount = formatAndValidateCurrency(amount.value)
  }

  if (tpaBenefitDistributionProcessingFee.value) {
    record.tpaBenefitDistributionProcessingFee = formatAndValidateCurrency(
      tpaBenefitDistributionProcessingFee.value
    )
  }

  if (
    transactionType.value.toLowerCase() ===
    BD_TRANSACTION_TYPES.check.toLocaleLowerCase()
  ) {
    inspiraBenefitDisbursementProcessingFee.value = '35'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }
  if (transactionType.value.toUpperCase() === BD_TRANSACTION_TYPES.ach) {
    inspiraBenefitDisbursementProcessingFee.value = '35'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
    transactionType.value = convertToUppercase(transactionType.value)
  }
  if (transactionType.value.toUpperCase() !== BD_TRANSACTION_TYPES.ach) {
    transactionType.value = convertToLowercase(transactionType.value)
  }
  if (paymentInstructions.value) {
    paymentInstructions.value = convertToLowercase(paymentInstructions.value)
  }

  if (
    [
      BD_TRANSACTION_TYPES.wire.toLocaleLowerCase(),
      BD_TRANSACTION_TYPES.overnight.toLocaleLowerCase(),
    ].includes(transactionType.value.toLowerCase())
  ) {
    inspiraBenefitDisbursementProcessingFee.value = '65'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }

  if (inspiraBenefitDisbursementProcessingFee.value) {
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }

  ///Distribution Amount Calculation Start///

  let amountInput = removeCurrencySymbolAndParse(amount.value)
  let tpaProcessingFeeInput = removeCurrencySymbolAndParse(
    tpaBenefitDistributionProcessingFee.value
  )
  let inspiraProcessingFeeInput = removeCurrencySymbolAndParse(
    inspiraBenefitDisbursementProcessingFee.value
  )

  let distributionAmountInput = (
    amountInput -
    (tpaProcessingFeeInput + inspiraProcessingFeeInput)
  ).toFixed(2)

  record.distributionAmount = formatAndValidateCurrency(
    distributionAmountInput.toString()
  )

  ///Distribution Amount Calculation End///
  if (stateForCheck.value) {
    const matchingState = findUSState(stateForCheck.value)
    if (matchingState) {
      stateForCheck.value = matchingState.value
    } else {
      stateForCheck.info = [
        { message: 'Must be a valid US state', level: 'error' },
      ]
    }
  }
  if (zipForCheck.value) {
    zipForCheck.value = zipForCheck.value.replaceAll(/[^a-zA-Z0-9- ]/g, '')
    const sanitizedZip = zipForCheck.value.match(numericRegex)?.join('')
    if (sanitizedZip) {
      zipForCheck.value = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zipForCheck.value = '0' + zipForCheck.value
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zipForCheck.value = '00' + zipForCheck.value
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zipForCheck.value = '000' + zipForCheck.value
      }
    }
    if (sanitizedZip.length > 5) {
      zipForCheck.value =
        zipForCheck.value.substring(0, 5) + '-' + zipForCheck.value.substring(5)
    }
  }

  return record
}

/* validation, especially presence validation, should be configured under 'fields' outlined in flatfile configuration-- 
 that way, if a file is missing required fields, flatfile would throw an error and prevent the user from proceeding to the "Review" step;
 However, some validations may be very complicated and not be achieved with flatfile validators. For such, use data hooks:  */
const validateRecord = (record) => {
  let {
    countryCode,
    city,
    state,
    zipCode,
    dob,
    ssn,
    emailAddress,
    transactionType,
    paymentInstructions,
    wireAch,
    abaRouting,
    wireAch2,
    wireAch3,
    ffcName,
    ffcNumber,
    accountType,
    checkPayableInfo,
    accountNumber,
    addressForCheck,
    cityForCheck,
    stateForCheck,
    zipForCheck,
  } = record
  if (countryCode.value === US_COUNTRY_VALUE) {
    if (zipCode.value) {
      const zipCodeRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
      const postalCode = zipCode.value.substring(0, 5)
      if (!zipCodeRe.test(zipCode.value)) {
        zipCode.info = [
          {
            message: 'US Zip Codes must have format: XXXXX or XXXXX-XXXX',
            level: 'error',
          },
        ]
      } else if (invalidPostalCodes.includes(postalCode)) {
        zipCode.info = [
          {
            message: 'Please enter a valid Zip Code',
            level: 'error',
          },
        ]
      }
    } else {
      // city and state are required if zip code is missing
      if (!city.value) city.info = requiredError
      if (!state.value) state.info = requiredError
      // zip code is required if both city and state are missing
      if (!city.value && !state.value) zipCode.info = requiredError
    }
  } else {
    if (state.value.length > 22) {
      state.info = [
        {
          message: 'Maximum length exceeded - must be 22 characters or less',
          level: 'error',
        },
      ]
    }
  }

  if (dob.value) {
    const dobDate = new Date(dob.value)
    if (isValid(dobDate)) {
      const currentDate = new Date()
      const maxDate = new Date(currentDate)
      maxDate.setFullYear(currentDate.getFullYear() - 100)
      const minDate = new Date(currentDate)
      minDate.setFullYear(currentDate.getFullYear() - 10)
      if (dobDate > minDate) {
        dob.info = [{ message: 'Must be over 10 years.', level: 'error' }]
      } else {
        if (isBefore(dobDate, maxDate)) {
          dob.info = [{ message: 'Must be under 100 years.', level: 'error' }]
        }
      }
    } else {
      const dateRe = new RegExp('^[0-9]{4}/[0-9]{2}/[0-9]{2}$')
      dob.info = dateRe.test(dob.value)
        ? [
            {
              message: 'Must be a valid date', // e.g., "2000/22/44"
              level: 'error',
            },
          ]
        : [
            {
              message: 'Format must be yyyy/mm/dd',
              level: 'error',
            },
          ]
    }
  }

  if (ssn.value) {
    const ssnRe = new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$')
    if (!ssnRe.test(ssn.value)) {
      ssn.info = [
        {
          message: 'Format must be XXX-XX-XXXX',
          level: 'error',
        },
      ]
    } else if (!validateTaxId(ssn.value)) {
      ssn.info = [
        {
          message: 'Given SSN is not valid',
          level: 'error',
        },
      ]
    }
  }

  if (emailAddress.value) {
    const schema = yup.string().email()
    const isValidEmail = schema.isValidSync(emailAddress.value)
    if (!isValidEmail) {
      emailAddress.info = [
        {
          message: 'Must be valid email format: example@email.com',
          level: 'error',
        },
      ]
    }
  }
  if (
    transactionType.value === BD_TRANSACTION_TYPES.ach &&
    paymentInstructions.value == BD_PAYMENT_INSTRUCTIONS.directRollover
  ) {
    paymentInstructions.info = [
      {
        message:
          'Transaction type of ACH & payment instructions of direct rollover is an invalid combination.',
        level: 'error',
      },
    ]
  }

  if (
    transactionType.value === BD_TRANSACTION_TYPES.wire ||
    transactionType.value === BD_TRANSACTION_TYPES.ach
  ) {
    if (!wireAch.value) {
      wireAch.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!abaRouting.value) {
      abaRouting.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!wireAch2.value) {
      wireAch2.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!wireAch3.value) {
      wireAch3.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
  }

  if (
    transactionType.value === BD_TRANSACTION_TYPES.wire &&
    paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover
  ) {
    if (!ffcName.value) {
      ffcName.info = [
        {
          message: 'Required for wire and direct rollover, blank if check',
          level: 'error',
        },
      ]
    }
    if (!ffcNumber.value) {
      ffcNumber.info = [
        {
          message: 'Required for wire and direct rollover, blank if check',
          level: 'error',
        },
      ]
    }
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.wire ||
      transactionType.value === BD_TRANSACTION_TYPES.ach) &&
    !accountType.value
  ) {
    accountType.info = [
      {
        message: 'Required if wire or ACH, blank if check',
        level: 'error',
      },
    ]
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check ||
      transactionType.value === BD_TRANSACTION_TYPES.overnight) &&
    !checkPayableInfo.value
  ) {
    checkPayableInfo.info = [
      {
        message: 'Required when check, blank if wire or ACH',
        level: 'error',
      },
    ]
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover) ||
    (transactionType.value === BD_TRANSACTION_TYPES.overnight &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover)
  ) {
    if (!accountNumber.value) {
      accountNumber.info = [
        {
          message:
            'Required for check and direct rollover, must be blank for Wire or ACH',
          level: 'error',
        },
      ]
    }
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover) ||
    (transactionType.value === BD_TRANSACTION_TYPES.overnight &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover)
  ) {
    if (!addressForCheck.value) {
      addressForCheck.info = [
        {
          message:
            'Required when transaction type is check and payments instructions is direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!cityForCheck.value) {
      cityForCheck.info = [
        {
          message:
            'Required when transaction type is check and payments instructions is direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!stateForCheck.value) {
      stateForCheck.info = [
        {
          message:
            'Required when Transaction type is check and payments instructions is direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!zipForCheck.value) {
      zipForCheck.info = [
        {
          message:
            'Required when transaction type is check and payments instructions is direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
  }
  if (zipForCheck.value) {
    const zipCodeRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
    const postalCode = zipForCheck.value.substring(0, 5)
    if (!zipCodeRe.test(zipForCheck.value)) {
      zipForCheck.info = [
        {
          message: 'US Zip Codes must have format: XXXXX or XXXXX-XXXX',
          level: 'error',
        },
      ]
    } else if (invalidPostalCodes.includes(postalCode)) {
      zipForCheck.info = [
        {
          message: 'Please enter a valid Zip Code',
          level: 'error',
        },
      ]
    }
  }

  return record
}

export default formatAndValidateBDRecord
