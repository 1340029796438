import { Path, COMPANY_NAME } from 'config/portal'
import { ProductType } from 'config/products'
import { Icon as AutomaticRolloversIcon } from 'images/product-icons/automatic-rollovers.svg'
import { Icon as UncashedChecksIcon } from 'images/product-icons/uncashed-checks.svg'
import { Icon as BenefitDistributionsIcon } from 'images/product-icons/benefit-distributions.svg'
import { Icon as SearchServicesIcon } from 'images/product-icons/search-services.svg'

// "productLink" = internal link that opens the service on the portal (if the product's currently not offered, use an external link to an existing service)
// "descriptionLink" = external link that describes the product (used for "Learn More")
function getProductConfig(productType) {
  switch (productType) {
    case ProductType.AUTOMATIC_ROLLOVERS:
      return {
        icon: AutomaticRolloversIcon,
        productLink: Path.SERVICES_AGREEMENTS,
        descriptionLink: '',
        text: 'We manage automatic rollovers and provide an easy way for retirement plans to remove costly, small balance accounts of former employees while preserving the tax-deferred status of their retirement savings.',
      }
    case ProductType.BENEFIT_DISTRIBUTIONS:
      return {
        icon: BenefitDistributionsIcon,
        // to be replaced, once the feature is built:
        productLink: Path.BD_SERVICES_AGREEMENTS,
        descriptionLink:
          'https://www.mtrustcompany.com/sites/default/files/uploads/docs/RS-302_BenefitDistributionServices.pdf',
        text: `The ${COMPANY_NAME.medium} Benefit Distribution service provides a fast and affordable solution for managing retirement plan distributions. Our services relieve the burden of issuing 1099Rs and withholding requirements, saving you time, money, and resources.`,
      }
    case ProductType.SEARCH_SERVICES:
      return {
        icon: SearchServicesIcon,
        productLink: '/products/search/history',
        descriptionLink: '',
        text: 'Missing participants from retirement plans can be a drain on resources, impair effective plan administration and expose plan providers to fiduciary risks. We partner with multiple search locator services to help find missing participants and enable easy follow-up.',
      }
    case ProductType.UNCASHED_CHECKS:
      return {
        icon: UncashedChecksIcon,
        // to be replaced, once the feature is built:
        productLink:
          'https://map.inspirafinancial.com/retirementservices/RetirementServices.aspx',
        descriptionLink: '',
        text: 'We are committed to helping plan sponsors fulfill their fiduciary responsibility to former employees by rolling over their uncashed checks into Safe Harbor IRAs to help preserve assets and reunite individuals with their accounts.',
      }
    default:
      return {}
  }
}

export default getProductConfig
