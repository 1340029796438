import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import {
  BackButton,
  FullPageSpinner,
  Spinner,
  Disclosure,
  StatusBadge,
  ActionMenu,
} from 'components'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { displayEin, displayAttribute, formatDate } from 'utils'
import { isEmpty, filter } from 'lodash'
import {
  UploadPlanAgreementModal,
  DownloadNotAvailableModal,
  ReInitializeDocusignModal,
} from '../components'
import { Path, AroActionItems, BDActionItems } from 'config/portal'
import {
  SigningMethod,
  ServicesAgreementStatus,
} from 'config/automatic-rollovers'
import { TOAST_ERROR_CONTACT_SUPPORT } from 'config/notifications'
import { flashErrorMessage } from 'redux-flash'
import * as actions from '../actions'
import { downloadServicesAgreementFile } from 'api'
import { TabBar } from 'lp-components'
import ServicesAgreementParticipants from './ServicesAgreementParticipants'

const propTypes = {
  fetchServicesAgreementDetails: PropTypes.func.isRequired,
  servicesAgreement: Types.servicesAgreementShow,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  clearServicesAgreementDetails: PropTypes.func.isRequired,
  fetchAvailableInitialInvestments: PropTypes.func.isRequired,
  availableInitialInvestments: PropTypes.arrayOf(
    Types.availableInitialInvestment
  ),
  shouldTriggerServicesAgreementDetailsFetch: PropTypes.bool.isRequired,
  setShouldTriggerServicesAgreementDetailsFetch: PropTypes.func.isRequired,
  setShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  agreementsType: PropTypes.string.isRequired,
}

const defaultProps = {
  servicesAgreement: null,
  availableInitialInvestments: null,
}

const PARTICIPANTS_INITIAL_FILTERS = {
  status: [],
}

const formatAddress = (sectionData, addressType) => {
  let addressComponents = []
  const orderedSuffixes = ['Street', 'State', 'City', 'PostalCode', 'Country']
  orderedSuffixes.forEach((suffix) => {
    const componentValue = sectionData[addressType + suffix]
    if (!componentValue) return
    addressComponents.push(componentValue)
  })

  return addressComponents.join(', ')
}

function PanelContent({
  sectionName,
  companyDetails,
  contactDetails,
  planEIN,
}) {
  const companyAddress = formatAddress(companyDetails, 'billing')
  const contactAddress = formatAddress(contactDetails, 'mailing')
  return (
    <>
      <div className="order-details-container">
        <dl>
          <div className="order-details-column">
            <dt>Company name</dt>
            <dd>{displayAttribute(companyDetails.name)}</dd>
          </div>
          {planEIN && (
            <div className="order-details-column">
              <dt>Employer Identification Number (EIN)</dt>
              <dd>{planEIN}</dd>
            </div>
          )}
          <div className="order-details-column">
            <dt>Company phone</dt>
            <dd>{displayAttribute(companyDetails.phone)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Company website</dt>
            <dd>{displayAttribute(companyDetails.website)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Street address</dt>
            <dd>{displayAttribute(companyAddress)}</dd>
          </div>
        </dl>
      </div>
      <hr />
      <div className="header-container">
        <h3>{sectionName} Contact Details</h3>
      </div>
      <div className="order-details-container">
        <dl>
          <div className="order-details-column">
            <dt>Contact name</dt>
            <dd>
              {displayAttribute(
                filter([
                  contactDetails.firstName,
                  contactDetails.lastName,
                ]).join(' ')
              )}
            </dd>
          </div>
          <div className="order-details-column">
            <dt>Title</dt>
            <dd>{displayAttribute(contactDetails.title)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Phone</dt>
            <dd>{displayAttribute(contactDetails.phone)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Email</dt>
            <dd>{displayAttribute(contactDetails.email)}</dd>
          </div>
          <div className="order-details-column">
            <dt>Street address</dt>
            <dd>{displayAttribute(contactAddress)}</dd>
          </div>
        </dl>
      </div>
    </>
  )
}

function NoDetailsProvidedCard({ sectionName }) {
  return (
    <div className="card no-details">
      <h2 className="section-header">{sectionName} Details</h2>
      <p className="not-provided">Not provided</p>
    </div>
  )
}

const Tab = {
  AGREEMENT: 'agreement',
  PARTICIPANTS: 'participants',
}

function PlanAgreementDetails({
  fetchServicesAgreementDetails,
  servicesAgreement,
  flashErrorMessageHandler,
  clearServicesAgreementDetails,
  fetchAvailableInitialInvestments,
  availableInitialInvestments,
  shouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerAllServicesAgreementsFetch,
  agreementsType,
}) {
  const history = useHistory()
  const [errorExists, setErrorExists] = useState(false)
  const [showDownloadNotAvailableModal, setShowDownloadNotAvailableModal] =
    useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const { servicesAgreementId } = useParams()
  const [currentTab, setCurrentTab] = useState(Tab.AGREEMENT)
  const [participantsFilters, setParticipantsFilters] = useState(
    PARTICIPANTS_INITIAL_FILTERS
  )
  const [participantsSearch, setParticipantsSearch] = useState('')
  const status = servicesAgreement?.portalAgreementStatus

  useEffect(() => {
    return () => {
      clearServicesAgreementDetails()
      setShouldTriggerServicesAgreementDetailsFetch(true)
    }
  }, [])

  useEffect(() => {
    async function getInitialInvestments() {
      try {
        await fetchAvailableInitialInvestments()
      } catch {
        setErrorExists(true)
      }
    }
    if (!availableInitialInvestments) getInitialInvestments()
  }, [availableInitialInvestments])

  useEffect(() => {
    async function getPlanAgreementDetails() {
      try {
        await fetchServicesAgreementDetails(servicesAgreementId)
        setShouldTriggerServicesAgreementDetailsFetch(false)
      } catch {
        setErrorExists(true)
      }
    }
    if (shouldTriggerServicesAgreementDetailsFetch) getPlanAgreementDetails()
  }, [shouldTriggerServicesAgreementDetailsFetch])

  const isLoading =
    (!servicesAgreement || !availableInitialInvestments) && !errorExists

  if (isLoading) return <FullPageSpinner />
  if (errorExists) {
    flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    return <Redirect to={Path.SERVICES_AGREEMENTS} />
  }

  const {
    name,
    portalAgreementStatus,
    createdDate,
    planEIN,
    planNumber,
    portalAgreementType,
    planType,
    planStatus,
    providerPlanId,
    planSponsorCompany,
    planSponsorContact,
    consultantCompany,
    consultantContact,
    TPACompany,
    TPAContact,
    recordKeeperCompany,
    recordKeeperContact,
    signingMethod,
  } = servicesAgreement

  const handleDownloadSelect = async () => {
    try {
      await downloadServicesAgreementFile(servicesAgreementId, name)
    } catch (e) {
      if (e.status === 422) setShowDownloadNotAvailableModal(true)
      else setErrorExists(true)
    }
  }

  const displayPlanEinValue = displayEin(planEIN)

  const isBenefitDistributionsDetails = location.pathname.includes(
    'benefit-distributions'
  )

  const agreementAudience = !isEmpty(TPACompany)
    ? TPACompany.audience
    : !isEmpty(consultantCompany)
    ? consultantCompany.audience
    : 'TPA'

  const fieldMappings = [
    { field: 'participantName', displayName: 'Participant name' },
    { field: 'accountType', displayName: 'Account type' },
    { field: 'status', displayName: 'Status' },
    { field: 'createdDate', displayName: 'Date created' },
    {
      field: 'initialInvestmentAmount',
      displayName: 'Initial investment amount',
    },
  ]

  const actionItems = [
    {
      externalLink: process.env.REACT_APP_ARO_FUNDING_INSTRUCTIONS_URL,
      text: AroActionItems.VIEW_FUNDING_INSTRUCTIONS,
    },
    {
      handleSelect: () => handleDownloadSelect(),
      text: 'Download completed agreement',
    },
  ]

  return (
    <div className="services-agreement-details-view">
      <div className="header-bar">
        <BackButton
          link={(location) => {
            return {
              pathname: isBenefitDistributionsDetails
                ? Path.BD_SERVICES_AGREEMENTS
                : Path.SERVICES_AGREEMENTS,
              search: location.state?.search,
            }
          }}
        >
          Back to all agreements
        </BackButton>
        <div className="header-bar-content">
          <h1 className="page-title">{name}</h1>
          <div className="services-agreements-actions">
            {status === ServicesAgreementStatus.COMPLETED && (
              <>
                <ActionMenu
                  items={actionItems}
                  ariaLabel="View automatic rollover action items"
                />
                <button
                  type="button"
                  onClick={() =>
                    history.push({
                      pathname: isBenefitDistributionsDetails
                        ? '/benefit-distributions/upload-file'
                        : '/automatic-rollovers/upload-file',
                      state: {
                        hasConfirmed: true,
                      },
                    })
                  }
                  className="button-primary"
                >
                  {agreementsType === 'Benefit Distributions'
                    ? BDActionItems.INITIATE_DISTRIBUTION
                    : AroActionItems.INITIATE_ROLLOVER}
                </button>
              </>
            )}
            {status === ServicesAgreementStatus.PENDING_SIGNATURES &&
              signingMethod === SigningMethod.GENERATE_DOCUMENT && (
                <>
                  <button
                    type="button"
                    onClick={() => setShowUploadModal(true)}
                    className="button-text"
                  >
                    {AroActionItems.UPLOAD_SIGNED_AGREEMENT}
                  </button>
                </>
              )}
            {status === ServicesAgreementStatus.PENDING_SIGNATURES &&
              signingMethod === SigningMethod.ELECTRONIC && (
                <>
                  <button
                    type="button"
                    onClick={() => setShowResendModal(true)}
                    className="button-text"
                  >
                    {AroActionItems.RESEND_DOCUSIGN_EMAIL}
                  </button>
                </>
              )}
            {status === ServicesAgreementStatus.PENDING_SIGNATURES && (
              <>
                <button
                  type="button"
                  onClick={() => handleDownloadSelect()}
                  className="button-primary"
                >
                  {AroActionItems.DOWNLOAD_AGREEMENT}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {portalAgreementStatus === ServicesAgreementStatus.COMPLETED && (
        <TabBar
          options={[
            { key: 'Agreement Details', value: Tab.AGREEMENT },
            { key: 'Participants', value: Tab.PARTICIPANTS },
          ]}
          value={currentTab}
          onChange={setCurrentTab}
        />
      )}
      {currentTab === Tab.AGREEMENT && (
        <>
          {shouldTriggerServicesAgreementDetailsFetch && <Spinner />}
          {isBenefitDistributionsDetails ? ( // BD Agreement Details
            <div className="card">
              <div className="header-container">
                <h2>Services agreement details</h2>
              </div>
              <div className="order-details-container">
                <dl>
                  <div className="order-details-column">
                    <dt>Participant name</dt>
                    <dd>{displayAttribute(name)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Status</dt>
                    <dd>
                      <StatusBadge status={portalAgreementStatus} />
                    </dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Funding method</dt>
                    <dd>{displayAttribute(portalAgreementType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Completion date</dt>
                    <dd>{displayAttribute(formatDate(createdDate))}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Distribution amount</dt>
                    <dd>{displayAttribute('$8,000.00')}</dd>
                  </div>
                </dl>
              </div>
            </div>
          ) : (
            // ARO Agreement Details
            <div className="card">
              <div className="header-container">
                <h2>Services agreement details</h2>
              </div>
              <div className="order-details-container">
                <dl>
                  <div className="order-details-column">
                    <dt>Plan name</dt>
                    <dd>{displayAttribute(name)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Agreement status</dt>
                    <dd>
                      <StatusBadge status={portalAgreementStatus} />
                    </dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Date created</dt>
                    <dd>{displayAttribute(formatDate(createdDate))}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Three-digit plan number (PN)</dt>
                    <dd>{displayAttribute(planNumber)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Provider plan ID #</dt>
                    <dd>{displayAttribute(providerPlanId)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Initial investment</dt>
                    <dd>{displayAttribute(portalAgreementType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Plan type</dt>
                    <dd>{displayAttribute(planType)}</dd>
                  </div>
                  <div className="order-details-column">
                    <dt>Plan status</dt>
                    <dd>{displayAttribute(planStatus)}</dd>
                  </div>
                </dl>
              </div>
            </div>
          )}
          <div className="optional-section">
            <div className="accordion-container">
              {isEmpty(planSponsorCompany) && isEmpty(planSponsorContact) ? (
                <NoDetailsProvidedCard sectionName="Plan Sponsor" />
              ) : (
                <Disclosure toggleText="Plan sponsor details" headingLevel={2}>
                  <PanelContent
                    sectionName="Plan Sponsor"
                    companyDetails={planSponsorCompany}
                    contactDetails={planSponsorContact}
                    planEIN={displayPlanEinValue}
                  />
                </Disclosure>
              )}
              {!isEmpty(TPACompany) && !isEmpty(TPAContact) ? (
                agreementAudience === 'TPA' ? (
                  <Disclosure toggleText="TPA details" headingLevel={2}>
                    <PanelContent
                      sectionName="TPA"
                      companyDetails={TPACompany}
                      contactDetails={TPAContact}
                    />
                  </Disclosure>
                ) : agreementAudience === 'Consultant' ? (
                  <Disclosure toggleText="Consultant details" headingLevel={2}>
                    <PanelContent
                      sectionName="Consultant"
                      companyDetails={TPACompany}
                      contactDetails={TPAContact}
                    />
                  </Disclosure>
                ) : (
                  <NoDetailsProvidedCard sectionName="TPA or Consultant" />
                )
              ) : !isEmpty(consultantCompany) && !isEmpty(consultantContact) ? (
                agreementAudience === 'TPA' ? (
                  <Disclosure toggleText="TPA details" headingLevel={2}>
                    <PanelContent
                      sectionName="TPA"
                      companyDetails={consultantCompany}
                      contactDetails={consultantContact}
                    />
                  </Disclosure>
                ) : agreementAudience === 'Consultant' ? (
                  <Disclosure toggleText="Consultant details" headingLevel={2}>
                    <PanelContent
                      sectionName="Consultant"
                      companyDetails={consultantCompany}
                      contactDetails={consultantContact}
                    />
                  </Disclosure>
                ) : (
                  <NoDetailsProvidedCard sectionName="TPA or Consultant" />
                )
              ) : (
                <NoDetailsProvidedCard sectionName="TPA or Consultant" />
              )}
              {isEmpty(recordKeeperCompany) && isEmpty(recordKeeperContact) ? (
                <NoDetailsProvidedCard sectionName="Recordkeeper" />
              ) : (
                <Disclosure toggleText="Recordkeeper details" headingLevel={2}>
                  <PanelContent
                    sectionName="Recordkeeper"
                    companyDetails={recordKeeperCompany}
                    contactDetails={recordKeeperContact}
                  />
                </Disclosure>
              )}
            </div>
          </div>
        </>
      )}
      {currentTab === Tab.PARTICIPANTS && (
        <ServicesAgreementParticipants
          isBenefitDistributionsDetails={isBenefitDistributionsDetails}
          servicesAgreementId={servicesAgreementId}
          baseFilters={PARTICIPANTS_INITIAL_FILTERS}
          filters={participantsFilters}
          handleFilter={(filters) => setParticipantsFilters(filters)}
          search={participantsSearch}
          handleSearch={(q) => setParticipantsSearch(q)}
          fieldMappings={fieldMappings}
          agreementsType={agreementsType}
        />
      )}
      {showUploadModal && (
        <UploadPlanAgreementModal
          onClose={() => setShowUploadModal(false)}
          servicesAgreement={servicesAgreement}
          onSubmitSuccess={() => {
            setShowUploadModal(false)
            setShouldTriggerServicesAgreementDetailsFetch(true)
            setShouldTriggerAllServicesAgreementsFetch(true)
          }}
        />
      )}
      {showDownloadNotAvailableModal && (
        <DownloadNotAvailableModal
          onClose={() => setShowDownloadNotAvailableModal(false)}
        />
      )}

      {showResendModal && (
        <ReInitializeDocusignModal
          agreementId={servicesAgreementId}
          onClose={() => setShowResendModal(false)}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    servicesAgreement: selectors.servicesAgreement(state),
    availableInitialInvestments: selectors.availableInitialInvestments(state),
    shouldTriggerServicesAgreementDetailsFetch:
      selectors.shouldTriggerServicesAgreementDetailsFetch(state),
  }
}

const mapDispatchToProps = {
  fetchServicesAgreementDetails: apiActions.fetchServicesAgreementDetails,
  flashErrorMessageHandler: flashErrorMessage,
  clearServicesAgreementDetails: actions.clearServicesAgreementDetails,
  fetchAvailableInitialInvestments: apiActions.fetchAvailableInitialInvestments,
  setShouldTriggerServicesAgreementDetailsFetch:
    actions.setShouldTriggerServicesAgreementDetailsFetch,
  setShouldTriggerAllServicesAgreementsFetch:
    actions.setShouldTriggerAllServicesAgreementsFetch,
}

PlanAgreementDetails.propTypes = propTypes
PlanAgreementDetails.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PlanAgreementDetails
)
