// Rather than referencing the entire utils folder, please import individual files
// in order to avoid a circular dependency between config & util files
import sortSelectOptions from 'utils/sortSelectOptions'

// App-wide config (routes, env vars) goes here.
export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const {
  REACT_APP_IDLE_TIMEOUT_DURATION,
  REACT_APP_IDLE_TIMEOUT_WARNING_DURATION,
} = process.env

export const FLASH_SUCCESS_TIMEOUT = 5000
export const FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT = 10000

export const COMPANY_NAME = {
  short: 'Inspira',
  medium: 'Inspira Financial',
  long: 'Inspira Financial',
  first: 'Inspira',
  full: 'Inspira Financial Trust, LLC',
}

// User persona values expected from fetching the userProfiles API
export const AccountAudience = {
  PLAN_FIDUCIARY: 'Plan Fiduciary',
  TPA: 'TPA',
  RECORDKEEPER: 'Recordkeeper',
}

export const RetirementServicesPortalProfile = {
  ADMIN: 'Admin',
  STANDARD: 'Standard',
}

export const Path = {
  SEARCH_TYPE: '/search/search-type',
  UPLOAD_FILE: '/search/upload-file',
  UPLOAD_SUMMARY: '/search/upload-summary',
  PERMISSIBLE_USE: '/search/permissible-use',
  CONFIRM_ORDER: '/search/confirm-submission',
  SERVICES_AGREEMENTS: '/products/automatic-rollovers/services-agreements',
  BD_SERVICES_AGREEMENTS: '/products/benefit-distributions/history',
  SEARCH_HISTORY: '/products/search/history',
  ADD_NEW_BD_SERVICES_AGREEMENT:
    '/benefit-distributions/add-new-services-agreement',
  SELECT_BD_COMPANY_INFORMATION:
    '/benefit-distributions/select-your-information',
  ADD_NEW_SERVICES_AGREEMENT: '/automatic-rollovers/add-new-services-agreement',
  SELECT_COMPANY_INFORMATION: '/automatic-rollovers/select-your-information',
  ADD_NEW_SEARCH_SERVICES_AGREEMENT: '/search/add-new-services-agreement',
  SERVICE_AGREEMENT_DETAILS: '/search/service-agreement-details',
  ADD_PAYMENT_DETAILS: '/profile/payment-details',
  CONFIRMED_SEARCH: '/search/mailing-preferences',
  FIDUCIARY_SEARCH: '/search/final-letter',
}

// Valid EIN prefixes
// https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
export const EIN_REGEX =
  /^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])-?\d{7}$/

export const PROVIDER_PLAN_ID_NUMBER_REGEX = /^[0-9a-zA-Z-]{0,30}$/

// These values are what's expected from the API
export const UserRole = {
  ADMIN: 'Admin',
  STANDARD: 'Standard',
}
export const ADMIN_ROLE_OPTION = {
  key: 'Administrator', // FE to convert 'Admin' to 'Administrator' for visual purposes (key only)
  value: UserRole.ADMIN, // Still use 'Admin' as the select option's value for form submission
}
export const userRoleOptions = [
  ADMIN_ROLE_OPTION,
  {
    key: UserRole.STANDARD,
    value: UserRole.STANDARD,
  },
]
export const sortedUserRoleOptions = sortSelectOptions(userRoleOptions, 'key')

export const UserStatus = {
  INVITED: 'Invited',
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
}

// // flow stages

export const ParticipantFileUploadStage = {
  selectRolloverType: 'select-rollover-type',
  participantFileUpload: 'participant-file-upload',
  planMapping: 'plan-mapping',
  reviewAndSubmit: 'review-and-submit',
}

export const CreateNewServicesAgreementStage = {
  ADD_NEW_SERVICES_AGREEMENT: 'add-new-services-agreement',
  SELECT_COMPANY_INFORMATION: 'select-company-information',
  AGREEMENT_DETAILS_AND_SUBMIT: 'agreement-details-and-submit',
}
export const CreateNewSearchServicesAgreementStage = {
  ADD_NEW_SEARCH_SERVICES_AGREEMENT: 'add-new-services-agreement',
  SUBMIT_SEARCH_AGREEMENT: 'review-and-submit',
}

export const NewSearchStage = {
  searchSelection: 'search-selection',
  uploadFile: 'upload-file',
  submissionDetails: 'submission-details',
  finalLetter: 'final-letter',
  mailingPreferences: 'mailing-preferences',
  confirmSubmission: 'confirm-submission',
}

export const SearchServicesNewServicesAgreement = {
  serviceAgreementDetails: 'service-agreement-details',
  addNewServicesAgreement: 'add-new-services-agreement',
}

export const CREATE_NEW_SERVICES_AGREEMENT_STAGES = [
  {
    name: CreateNewServicesAgreementStage.ADD_NEW_SERVICES_AGREEMENT,
    label: 'Identify plan & signature',
  },
  {
    name: CreateNewServicesAgreementStage.SELECT_COMPANY_INFORMATION,
    label: 'Review & confirm plan',
  },
  {
    name: CreateNewServicesAgreementStage.AGREEMENT_DETAILS_AND_SUBMIT,
    label: 'Provide agreement details & submit',
  },
]

export const PARTICIPANT_FILE_UPLOAD_STAGES = [
  {
    name: ParticipantFileUploadStage.participantFileUpload,
    label: 'Participant file instructions',
  },
  {
    name: ParticipantFileUploadStage.planMapping,
    label: 'Plan confirmation',
  },
  {
    name: ParticipantFileUploadStage.reviewAndSubmit,
    label: 'Review & submit',
  },
]

export const ARO_WORKFLOW_WITH_UNCASHED_CHECKS_STAGES = [
  {
    name: ParticipantFileUploadStage.selectRolloverType,
    label: 'Select rollover type',
  },
  ...PARTICIPANT_FILE_UPLOAD_STAGES,
]

export const NEW_SEARCH_STAGES = [
  {
    name: NewSearchStage.searchSelection,
    label: 'Search selection',
    path: Path.SEARCH_TYPE,
  },
  {
    name: NewSearchStage.uploadFile,
    label: 'Upload search file',
    path: Path.UPLOAD_FILE,
  },
  {
    name: NewSearchStage.submissionDetails,
    label: 'Permissible uses',
    path: Path.PERMISSIBLE_USE,
  },
  {
    name: NewSearchStage.confirmSubmission,
    label: 'Review & submit',
    path: Path.CONFIRM_ORDER,
  },
]
export const FIDUCIARY_SEARCH_STAGES = [
  {
    name: NewSearchStage.searchSelection,
    label: 'Search selection',
    path: Path.SEARCH_TYPE,
  },
  {
    name: NewSearchStage.uploadFile,
    label: 'Upload search file',
    path: Path.UPLOAD_FILE,
  },
  {
    name: NewSearchStage.submissionDetails,
    label: 'Permissible uses',
    path: Path.PERMISSIBLE_USE,
  },
  {
    name: NewSearchStage.finalLetter,
    label: 'Final letter',
    path: Path.FIDUCIARY_SEARCH,
  },
  {
    name: NewSearchStage.confirmSubmission,
    label: 'Review & submit',
    path: Path.CONFIRM_ORDER,
  },
]
export const CONFIRMED_SEARCH_STAGES = [
  {
    name: NewSearchStage.searchSelection,
    label: 'Search selection',
    path: Path.SEARCH_TYPE,
  },
  {
    name: NewSearchStage.uploadFile,
    label: 'Upload search file',
    path: Path.UPLOAD_FILE,
  },
  {
    name: NewSearchStage.submissionDetails,
    label: 'Permissible uses',
    path: Path.PERMISSIBLE_USE,
  },
  {
    name: NewSearchStage.mailingPreferences,
    label: 'Mailing preferences',
    path: Path.CONFIRMED_SEARCH,
  },
  {
    name: NewSearchStage.confirmSubmission,
    label: 'Review & submit',
    path: Path.CONFIRM_ORDER,
  },
]

export const NEW_AGREEMENT_STAGES = [
  {
    name: SearchServicesNewServicesAgreement.serviceAgreementDetails,
    label: 'Service agreement details',
  },
  {
    name: SearchServicesNewServicesAgreement.addNewServicesAgreement,
    label: 'Agreement & submit',
  },
]

export const StandardRadioGroupOptions = [
  {
    key: 'Yes',
    value: 'true',
  },
  {
    key: 'No',
    value: 'false',
  },
]

// JS code snippet (executable script) to implement GetFeedback form to the app.
// Generated and managed by MTCs marketing team
export const GET_FEEDBACK_SCRIPT = `/*{literal}<![CDATA[*/window.lightningjs||function(c){function g(b,d){d&&(d+=(/\\?/.test(d)?"&":"?")+"lv=1");c[b]||function(){var i=window,h=document,j=b,g=h.location.protocol,l="load",k=0;(function(){function b(){a.P(l);a.w=1;c[j]("_load")}c[j]=function(){function m(){m.id=e;return c[j].apply(m,arguments)}var b,e=++k;b=this&&this!=i?this.id||0:0;(a.s=a.s||[]).push([e,b,arguments]);m.then=function(b,c,h){var d=a.fh[e]=a.fh[e]||[],j=a.eh[e]=a.eh[e]||[],f=a.ph[e]=a.ph[e]||[];b&&d.push(b);c&&j.push(c);h&&f.push(h);return m};return m};var a=c[j]._={};a.fh={};a.eh={};a.ph={};a.l=d?d.replace(/^\\/\\//,(g=="https:"?g:"http:")+"//"):d;a.p={0:+new Date};a.P=function(b){a.p[b]=new Date-a.p[0]};a.w&&b();i.addEventListener?i.addEventListener(l,b,!1):i.attachEvent("on"+l,b);var q=function(){function b(){return["<head></head><",c,' onload="var d=',n,";d.getElementsByTagName('head')[0].",d,"(d.",g,"('script')).",i,"='",a.l,"'\\"></",c,">"].join("")}var c="body",e=h[c];if(!e)return setTimeout(q,100);a.P(1);var d="appendChild",g="createElement",i="src",k=h[g]("div"),l=k[d](h[g]("div")),f=h[g]("iframe"),n="document",p;k.style.display="none";e.insertBefore(k,e.firstChild).id=o+"-"+j;f.frameBorder="0";f.id=o+"-frame-"+j;/MSIE[ ]+6/.test(navigator.userAgent)&&(f[i]="javascript:false");f.allowTransparency="true";l[d](f);try{f.contentWindow[n].open()}catch(s){a.domain=h.domain,p="javascript:var d="+n+".open();d.domain='"+h.domain+"';",f[i]=p+"void(0);"}try{var r=f.contentWindow[n];r.write(b());r.close()}catch(t) {
f[i]=p+'d.write("'+b().replace(/"/g,String.fromCharCode(92)+'"')+'");d.close();'}a.P(2)};
a.l&&setTimeout(q,0)})()}();c[b].lv="1";return c[b]}var o="lightningjs",k=window[o]=g(o);k.require=g;k.modules=c}({}); if(!navigator.userAgent.match(/Android|BlackBerry|BB10|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {window.usabilla_live = lightningjs.require("usabilla_live", "//w.usabilla.com/7c8b986a9f88.js"); } else {window.usabilla_live = lightningjs.require("usabilla_live", "//w.usabilla.com/57664cd6c9ff.js"); }/*]]>{/literal}*/`

export function isGetFeedbackEnabled() {
  return process.env.ENABLE_GET_FEEDBACK
}

export function isFullStoryEnabled() {
  return process.env.ENABLE_FULLSTORY
}

export function isSearchServicesEnabled() {
  return process.env.ENABLE_SEARCH_SERVICES
}

export function isBenefitDistributionsAgreementsEnabled() {
  return process.env.ENABLE_BENEFIT_DISTRIBUTIONS_AGREEMENTS
}

export function isBenefitDistributionsEnabled() {
  return process.env.ENABLE_BENEFIT_DISTRIBUTIONS
}

export function isSearchPaymentEnabled() {
  return isSearchServicesEnabled() && process.env.ENABLE_SEARCH_PAYMENT
}

export function isUncashedCheckExperienceEnabled() {
  return process.env.ENABLE_UNCASHED_CHECK_EXPERIENCE
}

export function isFlatfileV4Enabled() {
  return process.env.ENABLE_FLATFILE_V4_EXPERIENCE
}

export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID

export const AroActionItems = {
  INITIATE_ROLLOVER: 'Initiate rollover',
  CREATE_NEW_SERVICES_AGREEMENT: 'Create new services agreement',
  UPLOAD_SIGNED_AGREEMENT: 'Upload signed agreement',
  VIEW_FUNDING_INSTRUCTIONS: 'View funding instructions',
  DOWNLOAD_AGREEMENT: 'Download Agreement',
  RESEND_DOCUSIGN_EMAIL: 'Resend DocuSign email',
}

export const BDActionItems = {
  INITIATE_DISTRIBUTION: 'Initiate distribution',
  CREATE_NEW_SERVICES_AGREEMENT: 'Create new services agreement',
  UPLOAD_SIGNED_AGREEMENT: 'Upload signed agreement',
  VIEW_FUNDING_INSTRUCTIONS: 'View Funding Instructions',
  DOWNLOAD_AGREEMENT: 'Download agreement',
  RESEND_DOCUSIGN_EMAIL: 'Resend DocuSign email',
}

export const SearchActionItems = {
  CREATE_NEW_SEARCH_SERVICES_AGREEMENT: 'Create New Services Agreement',
  START_NEW_SEARCH: 'Start new search',
  PROVIDE_PAYMENT_DETAILS: 'Payment Details',
  ADD_PAYMENT_DETAILS: 'Add payment details',
  DOWNLOAD_AGREEMENT: 'Download agreement',
}

export const WorkFlowTitles = {
  SEARCH_SERVICES: 'Start new search',
  INITIATE_ROLLOVER: AroActionItems.INITIATE_ROLLOVER,
  CREATE_NEW_SERVICES_AGREEMENT:
    AroActionItems['CREATE_NEW_SERVICES_AGREEMENT'],
  CREATE_NEW_SEARCH_SERVICES_AGREEMENT: 'Create New Services Agreement',
}

export const YOUTUBE_BASE_URL = 'https://www.youtube.com/watch?v='

export const MANUAL_ERISAPEDIA_OPTION = 'manual'

export const CREDIT_CARD_OPTION = {
  key: 'Credit Card',
  value: 'Credit Card',
}

export const paymentTypeOptions = [CREDIT_CARD_OPTION]

export const LEGAL_DISCLAIMERS = {
  footer: `${COMPANY_NAME.full} and its affiliates perform the duties of a directed custodian and/or an administrator of consumer directed benefits and, as such, do not provide due diligence to third parties on prospective investments, platforms, sponsors, or service providers, and do not offer or sell investments or provide investment, tax, or legal advice.`,
  trademark: `${COMPANY_NAME.short} and ${COMPANY_NAME.long} are trademarks of ${COMPANY_NAME.full}.`,
}
