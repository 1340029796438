import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { ProductPortal } from 'components'

const propTypes = {
  subscribedProducts: PropTypes.arrayOf(Types.subscribedProduct).isRequired,
  currentUser: PropTypes.object,
}

const defaultProps = {}

function Home({ subscribedProducts, currentUser }) {
  return (
    <div className="home container">
      <h1>Welcome, {currentUser.firstName}</h1>
      <ProductPortal subscribedProducts={subscribedProducts} />
    </div>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    subscribedProducts: globalSelectors.orderedSubscribedProducts(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
