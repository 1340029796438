import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { FlowActions, SearchRecordsSummaryTable } from '../components'
import { Redirect, useHistory } from 'react-router-dom'
import { Path } from 'config/portal'
import { TOAST_ERROR_CONTACT_SUPPORT_EMAIL } from 'config/notifications'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { isEmpty } from 'lodash'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { flashErrorMessage } from 'redux-flash'
import { selectors as apiSelectors } from 'lp-redux-api'
import { Icon as DownloadIcon } from 'images/download.svg'
import { displayInteger, downloadDiscardedParticipants } from 'utils'
import { RecordTypes } from 'flatfile-config'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ConfirmationCheckbox, ScrollErrorForm } from 'components'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  generateSearchOrderCost: PropTypes.func.isRequired,
  isGeneratingSearchOrderCost: PropTypes.bool.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  discardedParticipants: PropTypes.arrayOf(Types.searchParticipant).isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function UploadSummaryView({
  newSearchFlowData,
  setNewSearchFlowData,
  generateSearchOrderCost,
  isGeneratingSearchOrderCost,
  flashErrorMessageHandler,
  discardedParticipants,
}) {
  const history = useHistory()
  const formRef = useRef()

  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />

  const handleContinue = async () => {
    try {
      const { searchType, recordsData } = newSearchFlowData
      const searchOrderCost = await generateSearchOrderCost({
        searchService: searchType,
        numberOfSearches: recordsData.length,
      })
      setNewSearchFlowData({
        ...newSearchFlowData,
        total: searchOrderCost.totalOrderCost,
      })
      history.push(Path.PERMISSIBLE_USE)
    } catch (e) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT_EMAIL)
    }
  }

  return (
    <>
      <div className="card full-height upload-summary-view-container">
        <div className="form-masthead">
          <h2>Review and confirm your participant file to continue</h2>
          <p>
            You can go back if you need to make changes to your participant
            data.
          </p>
        </div>
        <div className="form-section form-section--with-top-border">
          <SearchRecordsSummaryTable data={newSearchFlowData.recordsData} />
        </div>
        <div className="form-section">
          <Formik
            initialValues={{
              confirm: false,
            }}
            validationSchema={Yup.object({
              confirm: Yup.boolean()
                .required('Required')
                .oneOf([true], 'Required'),
            })}
            onSubmit={handleContinue}
            innerRef={formRef}
          >
            <ScrollErrorForm>
              <ConfirmationCheckbox
                name="confirm"
                label="I confirm that the Plans named and reflected on this Search Order are the Plans for which Plan Agent acts as Plan Fiduciary or Plan Service Provider, in its capacity as authorized agent of a Plan Fiduciary, and for which Plan Agent requests Search Services pursuant to the terms of the Agreement, as of the Search Order's Submission Date."
              />
            </ScrollErrorForm>
          </Formik>
        </div>
        {!isEmpty(discardedParticipants) && (
          <div className="form-section discarded-participants">
            <div className="form-header">
              <h3 className="is-marginless">
                Participants not included in submission
              </h3>
            </div>
            <p>
              Some participants will not be included in your submission due to
              issues with those participant's data. Please download the data for
              these participants for your records and resubmit them after the
              issues has been resolved.
            </p>
            <div className="download-container">
              <span>
                Total discarded participants:{' '}
                {displayInteger(discardedParticipants.length)}
              </span>
              <button
                type="button"
                onClick={() =>
                  downloadDiscardedParticipants({
                    participants: discardedParticipants,
                    originalFileName: 'my-searches',
                    recordType: RecordTypes.SEARCH,
                  })
                }
              >
                <DownloadIcon aria-hidden="true" />
                Download participant data
              </button>
            </div>
          </div>
        )}
      </div>
      <FlowActions
        isSubmitting={isGeneratingSearchOrderCost}
        handleSubmit={() => formRef.current.handleSubmit()}
      />
    </>
  )
}

UploadSummaryView.propTypes = propTypes
UploadSummaryView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
    discardedParticipants: selectors.discardedParticipants(state),
    isGeneratingSearchOrderCost: apiSelectors.isLoading(
      state,
      apiActions.generateSearchOrderCost
    ),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
  generateSearchOrderCost: apiActions.generateSearchOrderCost,
  flashErrorMessageHandler: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadSummaryView
)
