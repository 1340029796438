import React, { useRef, useState } from 'react'
import {
  useFlatfile,
  Space,
  useListener,
  Workbook,
  Document,
} from '@flatfile/react'
import {
  RecordNames,
  RecordTypes,
  getFlatfileSchema,
  UploadInstructions,
} from 'flatfile-config'
import { convertHook } from '@flatfile/v2-shims'
import api from '@flatfile/api'
import { FileNameContainer } from 'components'
import { recordHook } from '@flatfile/plugin-record-hook'
import { isEmpty, noop, has, toArray } from 'lodash'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { listeners } from '../listeners'

const propTypes = {
  recordType: PropTypes.oneOf(Object.values(RecordTypes)).isRequired,
  validators: PropTypes.object,
  buttonContent: PropTypes.node.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  handleRecords: PropTypes.func,
  handleData: PropTypes.func.isRequired,
  handleRecordsSubmit: PropTypes.func,
}

const defaultProps = {
  validators: {},
  handleRecords: noop,
  handleRecordsSubmit: noop,
  fileName: null,
  setFileName: noop,
}

/**
 * This function finds missing fields from the list of records using the list of required field keys
 *
 * @param {object} record
 * @param {string[]} fields
 * @returns {string[]} a list missing fields
 */
function findMissingFields(record, fields) {
  return fields.filter((field) => !has(record, field))
}

/**
 * Given an array of records and an array of fields, assign each field with an empty string to each record:
 * @param {object[]} records
 * @param {string[]} fields
 * @returns {*}
 */
function assignFields(records, fields) {
  return fields.reduce((accumulatedRecords, field) => {
    return accumulatedRecords.map((record) => ({
      ...record,
      [field]: '',
    }))
  }, records)
}

const FlatfileUpload = ({
  recordType,
  validators,
  buttonContent,
  handleData,
  handleRecordsSubmit,
  handleRecords,
  fileName,
  setFileName,
}) => {
  const { open, openPortal, closePortal } = useFlatfile()
  const isUploadComplete = useRef(false)
  const uploadedFileName = useRef('')
  const [fileId, setFileId] = useState('')

  const toggleFlatfileUpload = () => {
    if (!open) {
      openPortal()
    } else {
      closePortal()
    }
  }

  const flatfileConfig = {
    ...getFlatfileSchema(recordType, validators),
    actions: [
      {
        operation: 'submitAction',
        mode: 'foreground',
        label: 'Continue',
        type: 'string',
        description: 'Submit data',
        primary: true,
        constraints: [{ type: 'hasData' }],
      },
    ],
  }

  const fieldKeys = flatfileConfig.sheets[0].fields.map((field) => field.key)

  useListener((listener) => {
    listener.on(
      'job:ready',
      {
        job: 'workbook:submitAction',
      },
      async (event) => {
        const { jobId, workbookId, spaceId } = event.context
        const { data: workbookSheets } = await api.sheets.list({ workbookId })
        const fileName = (await api.files.get(fileId)).data.name
        uploadedFileName.current = fileName

        try {
          workbookSheets.forEach(async (sheet) => {
            const data = await api.records.get(sheet.id)
            let validData = []
            let invalidData = []

            await api.jobs.ack(jobId, {
              info: 'Starting job to process the submitted record',
              progress: 10,
            })

            data.data.records.forEach((record) => {
              const { valid, values } = record

              const transformedValues = Object.fromEntries(
                toArray(values).map((curr, index) => [
                  fieldKeys[index],
                  curr.value,
                ])
              )
              const composedRecords = [{ values: transformedValues, valid }]
              for (let i = 0; i < composedRecords.length; i++) {
                const { values, valid } = composedRecords[i]
                const record = { ...values, row: i + 1 }

                if (valid) {
                  validData.push(record)
                } else {
                  invalidData.push(record)
                }
              }
            })

            setFileName(uploadedFileName.current)

            const missingFields = findMissingFields(validData[0], fieldKeys)
            const validDataWithAllFields = assignFields(
              validData,
              missingFields
            )

            await api.jobs.ack(jobId, {
              info: 'Job is completed',
              progress: 100,
            })

            const validDataExists = !isEmpty(validData)
            const invalidDataExists = !isEmpty(invalidData)

            if (validDataExists && !invalidDataExists) {
              isUploadComplete.current = validDataExists
              await api.jobs.complete(jobId, {
                outcome: {
                  acknowledge: true,
                  message: `Success! your ${RecordNames[
                    recordType
                  ].toLowerCase()} information has been captured`,
                },
              })
              handleData(validDataWithAllFields, invalidData)
              closePortal()
              handleRecordsSubmit && handleRecordsSubmit()
            } else if (invalidDataExists && validDataExists) {
              await api.jobs.complete(jobId, {
                outcome: {
                  acknowledge: true,
                  hideDefaultButton: true,
                  next: {
                    type: 'id',
                    id: spaceId,
                    path: `workbook/${workbookId}/sheet/${sheet.id}`,
                    query: 'filter=error',
                    label: 'Review invalid rows',
                  },
                  heading:
                    'You have one or more rows with unresolved formatting issues.',
                  message: `Click on the Go to invalid records button and fix the invalid record before submitting again.\n You can delete the invalid records.`,
                },
              })
            } else {
              await api.jobs.fail(jobId, {
                outcome: {
                  message: `No valid records were submitted. Please try again`,
                },
              })
            }
          })
        } catch (error) {
          await api.jobs.fail(jobId, {
            outcome: {
              message: `No valid records were submitted. Please try again. ${error}`,
            },
          })
          return
        }
      }
    )

    listener.on('upload:completed', async ({ context: { fileId } }) => {
      setFileId(fileId)
    })

    listener.use(
      recordHook(flatfileConfig.sheets[0].slug, (record) => {
        return convertHook(handleRecords)(record)
      })
    )
    // listener.on('**', (event) => {
    //   console.log(`Received event: ${event.topic}`)
    // })
  })

  // Apply specific module listeners
  useListener((listener) => listeners[recordType](listener))

  return (
    <>
      {fileName ? (
        <FileNameContainer
          fileName={fileName}
          handleUpload={() => {
            toggleFlatfileUpload()
          }}
        />
      ) : (
        <button
          className="button-primary-outline upload-file is-fullwidth"
          onClick={toggleFlatfileUpload}
        >
          {buttonContent}
        </button>
      )}

      <Space
        config={{
          name: `${RecordNames[recordType]} Record`,
          metadata: {
            // theme: {
            //   root: {
            //     // USED Throughout the application
            //     primaryColor: '#4553A9',
            //     dangerColor: '#E40901',
            //     // We are making this darker for accessibility
            //     dangerLessLightColor: '#A61400',
            //     warningColor: '#E40901',
            //     successColor: '#318500',
            //     actionColor: '#4553A9',
            //     actionLighterColor: '#1B216B',
            //     borderColor: '#4a453b',
            //     text: '#4a453b',
            //     backgroundColor: '#E9E7E2',

            //     // Badges
            //     badgeBorderColor: '#4553A9',

            //     // Dropdowns, Text Inputs, and Context Menus
            //     interactiveBorderColor: '#4553A9',

            //     // Tabstrip
            //     tabstripActiveColor: '#1B216B',
            //     tabstripInactiveColor: '#4553A9',
            //     tabstripHoverTextColor: '#4553A9',
            //     tabstripHoverBorderColor: '#4553A9',

            //     // Popovers
            //     popoverBackgroundColor: '#4553A9',

            //     // ToolTips
            //     tooltipBackgroundColor: '#1C1C1C',
            //   },
            //   // See reference for all possible variables
            //   // https://flatfile.com/docs/guides/theming
            //   table: {
            //     fontFamily: 'inter',
            //     cell: {
            //       active: {
            //         borderWidth: '2px',
            //         borderShadow: 'gray',
            //       },
            //       number: {
            //         fontFamily: 'inter',
            //       },
            //     },
            //     filters: {
            //       outerBorderRadius: '4px',
            //       innerBorderRadius: '4px',
            //       color: '#4553A9',
            //       secondaryBackgroundColor: '#E9E7E2',
            //       secondaryColor: 'black',
            //       dangerColor: '#E40901',
            //       error: {
            //         secondaryColor: 'black',
            //       },
            //     },
            //     buttons: {
            //       iconColor: '#4553A9',
            //       pill: {
            //         color: '#4553A9',
            //         backgroundColor: 'dark blue',
            //       },
            //     },
            //     column: {
            //       header: {
            //         color: '#1c1c1c',
            //         backgroundColor: '#e9e7e2',
            //       },
            //     },
            //     indexColumn: {
            //       backgroundColor: '#e9e7e2',
            //       color: '#1c1c1c',
            //       selected: {
            //         backgroundColor: '#cbc7be',
            //       },
            //     },
            //     inputs: {
            //       checkbox: {
            //         color: '#4553A9',
            //         borderColor: '#4553A9',
            //       },
            //     },
            //   },
            // },
            sidebarConfig: {
              showSidebar: true,
            },
          },
        }}
      >
        <Document
          config={{
            title: 'Upload Instructions',
            body: UploadInstructions[recordType],
          }}
        />
        <Workbook config={flatfileConfig} />
      </Space>
    </>
  )
}

FlatfileUpload.propTypes = exact(propTypes)
FlatfileUpload.defaultProps = defaultProps

export default React.memo(FlatfileUpload)
