export const BD_TRANSACTION_TYPES = {
  check: 'check',
  wire: 'wire',
  ach: 'ACH',
  overnight: 'check/overnight delivery',
}
export const BD_PAYMENT_INSTRUCTIONS = {
  directRollover: 'direct rollover',
  directToParticipant: 'direct to participant',
}
