import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Modal, Button, SubmitButton, Table, TableColumn } from 'lp-components'
import { formatUrl, formatDate, sortSelectOptions } from 'utils'
import { Path } from 'config/portal'
import { Link } from 'react-router-dom'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitContent: PropTypes.node.isRequired,
  agreements: PropTypes.arrayOf(Types.servicesAgreementSummary).isRequired,
}

const defaultProps = {}

function DuplicateServicesAgreementModal({
  onClose,
  handleBack,
  isSubmitting,
  handleSubmit,
  submitContent,
  agreements,
}) {
  return (
    <Modal id="duplicate-services-agreement-modal" onClose={onClose}>
      <h1>Duplicate services agreement</h1>
      <p>
        The Services Agreement that you are creating has the same Employer
        Identification Number (EIN) as one or more Services Agreements we
        already have on file. In order to prevent creating duplicate agreements,
        please view the existing agreement(s) below.
      </p>
      <p>
        If the Services Agreement you are creating is a new agreement for a
        retirement plan for which a Services Agreement is not already on file,
        please select "Continue" to proceed. This may be the case if you work
        with a Plan Sponsor who offers multiple retirement plans.
      </p>
      <p>
        If you would like to make a change to one of the Services agreements,
        please contact your Relationship Manager for assistance.
      </p>
      <Table data={sortSelectOptions(agreements, 'name')}>
        <TableColumn name="name" label="Plan name" />
        <TableColumn name="portalAgreementType" label="Initial investment" />
        <TableColumn
          name="portalAgreementDate"
          label="Date created"
          format={(val) => formatDate(val)}
        />
        <TableColumn
          name="viewDetails"
          headerComponent={({ column: { label } }) => (
            <th>
              <div className="visually-hidden">{label}</div>
            </th>
          )}
          component={({ data: { serviceAgreementID, name } }) => (
            <td className="view-details">
              <Link
                to={formatUrl(
                  `${Path.BD_SERVICES_AGREEMENTS}/${serviceAgreementID}`
                )}
                className="link-text"
                aria-label={`View details for ${name}`}
              >
                View details
              </Link>
            </td>
          )}
        />
      </Table>
      <div className="form-actions">
        <SubmitButton submitting={isSubmitting} onClick={handleSubmit}>
          {submitContent}
        </SubmitButton>
        <Button onClick={handleBack} variant="text">
          Back to new services agreement
        </Button>
      </div>
    </Modal>
  )
}

DuplicateServicesAgreementModal.propTypes = exact(propTypes)
DuplicateServicesAgreementModal.defaultProps = defaultProps

export default React.memo(DuplicateServicesAgreementModal)
