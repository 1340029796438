import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { CompletePlanAgreementForm } from '../forms'
import { flashSuccessMessage, flashErrorMessage } from 'redux-flash'
import {
  AccountAudience,
  Path,
  FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT,
} from 'config/portal'
import {
  CompanyType,
  InvestmentType,
  valicFiveInvestmentTypes,
  lincolnInvestmentTypes,
  SigningMethod,
  signingMethodOptions,
  AudienceType,
} from 'config/automatic-rollovers'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  NewAgreementSuccessMessages,
} from 'config/notifications'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { FullPageSpinner, ComponentFailure, Spinner } from 'components'
import { createAudienceObject, getEntityInitialValues } from 'utils'
import * as Types from 'types'
import { compact, isEmpty, find, mergeAll, concat } from 'lodash'
import { selectors as globalSelectors } from '../../reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import * as productsActions from '../../products/actions'
import { selectors as productSelectors } from '../../products/reducer'

const propTypes = {
  flashErrorMessageHandler: PropTypes.func.isRequired,
  fetchPlanSponsors: PropTypes.func.isRequired,
  fetchRecordkeepers: PropTypes.func.isRequired,
  fetchTpas: PropTypes.func.isRequired,
  fetchConsultants: PropTypes.func.isRequired,
  transformedPlanSponsors: PropTypes.arrayOf(Types.audienceDetails),
  transformedRecordkeepers: PropTypes.arrayOf(Types.audienceDetails),
  transformedTpas: PropTypes.arrayOf(Types.audienceDetails),
  transformedConsultants: PropTypes.arrayOf(Types.audienceDetails),
  availableInitialInvestments: PropTypes.arrayOf(
    Types.availableInitialInvestment
  ),
  fetchAvailableInitialInvestments: PropTypes.func.isRequired,
  completeServicesAgreementForm: Types.completeServicesAgreementForm,
  currentUser: Types.user.isRequired,
  submitServicesAgreementForm: PropTypes.func.isRequired,
  isSubmittingServicesAgreementForm: PropTypes.bool.isRequired,
  setShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  flashSuccessMessageHandler: PropTypes.func.isRequired,
}

const defaultProps = {
  transformedPlanSponsors: null,
  transformedRecordkeepers: null,
  ttransformedTpaspas: null,
  transformedConsultants: null,
  availableInitialInvestments: null,
  completeServicesAgreementForm: null,
}

const electronicSignatureOption = signingMethodOptions.find(
  (option) => option.key === SigningMethod.ELECTRONIC_SIGNATURE
)

const ENTERPRISE_RK_LIST = [
  'American Funds/Capital Group',
  'Transamerica Retirement Management',
  'Empower',
]

const generateSuccessMessage = ({ recordkeeperName, signingMethod }) => {
  if (ENTERPRISE_RK_LIST.includes(recordkeeperName)) {
    return NewAgreementSuccessMessages.ENTERPRISE_RECORD_KEEPER
  } else if (signingMethod === electronicSignatureOption.value) {
    return NewAgreementSuccessMessages.ELECTRONIC_SIGNATURE
  } else {
    return NewAgreementSuccessMessages.WET_SIGNATURE
  }
}

function findExistingEntity(id, entities) {
  if (!entities) return
  return entities.find((entity) => entity.company.ID === id)
}

// Util to create an object for a selected existing service agreement to pass into initialValues
function createExistingEntityObject({ id, entities }) {
  const existingEntity = findExistingEntity(id, entities)
  if (!existingEntity) return
  return getEntityInitialValues(existingEntity)
}

function CompletePlanAgreementView({
  flashErrorMessageHandler,
  fetchAvailableInitialInvestments,
  fetchPlanSponsors,
  fetchRecordkeepers,
  fetchTpas,
  fetchConsultants,
  transformedPlanSponsors,
  transformedRecordkeepers,
  transformedTpas,
  transformedConsultants,
  availableInitialInvestments,
  completeServicesAgreementForm,
  currentUser,
  submitServicesAgreementForm,
  isSubmittingServicesAgreementForm,
  setShouldTriggerAllServicesAgreementsFetch,
  flashSuccessMessageHandler,
}) {
  if (isEmpty(completeServicesAgreementForm))
    return <Redirect to={Path.ADD_NEW_BD_SERVICES_AGREEMENT} />

  const [error, setError] = useState(false)
  const history = useHistory()

  const isPlanFiduciary =
    currentUser.accountAudience === AccountAudience.PLAN_FIDUCIARY
  const { allowPlanSponsorSelection } = completeServicesAgreementForm
  const allowTpaConsultantSelection =
    currentUser.accountAudience === AccountAudience.RECORDKEEPER ||
    isPlanFiduciary
  const allowRecordkeeperSelection =
    currentUser.accountAudience === AccountAudience.TPA || isPlanFiduciary

  useEffect(() => {
    async function getExistingRecords() {
      let promises = [fetchAvailableInitialInvestments()]

      if (allowPlanSponsorSelection) promises.push(fetchPlanSponsors())
      if (allowRecordkeeperSelection) promises.push(fetchRecordkeepers())
      if (allowTpaConsultantSelection)
        promises.push(fetchTpas(), fetchConsultants())

      try {
        await Promise.all(promises)
      } catch {
        setError(true)
      }
    }
    getExistingRecords()

    // On unmount, reset location.state from Add Plan Agreement Modal - unset state whether it's for a new or existing services agreement
    return () => history.replace()
  }, [
    allowPlanSponsorSelection,
    allowRecordkeeperSelection,
    allowTpaConsultantSelection,
    fetchAvailableInitialInvestments,
    fetchPlanSponsors,
    fetchTpas,
    fetchConsultants,
    fetchRecordkeepers,
    setError,
    history,
  ])

  const isLoading =
    ((!transformedPlanSponsors && allowPlanSponsorSelection) ||
      (!transformedRecordkeepers && allowRecordkeeperSelection) ||
      (!transformedTpas &&
        !transformedConsultants &&
        allowTpaConsultantSelection) ||
      !availableInitialInvestments) &&
    !error

  if (isLoading)
    return (
      <div className="complete-services-agreement-view">
        <Spinner />
      </div>
    )
  if (error) return <ComponentFailure />

  const tpasAndConsultants = compact(
    concat(transformedTpas, transformedConsultants)
  )

  const existingPlanSponsorObject = createExistingEntityObject({
    id: completeServicesAgreementForm.planSponsor,
    entities: transformedPlanSponsors,
  })
  const existingRecordkeeperObject = createExistingEntityObject({
    id: completeServicesAgreementForm.recordkeeper,
    entities: transformedRecordkeepers,
  })
  const existingTpaConsultantObject = createExistingEntityObject({
    id: completeServicesAgreementForm.tpaConsultant,
    entities: tpasAndConsultants,
  })

  const findAgreementInfo = (agreementTemplateName) => {
    return availableInitialInvestments.find(
      (investment) => investment.templateName === agreementTemplateName
    )
  }
  const getAgreementType = () => {
    if (currentUser.accountAudience === AccountAudience.RECORDKEEPER) {
      switch (currentUser.accountName) {
        case CompanyType.NATIONWIDE:
          return findAgreementInfo(InvestmentType.NATIONWIDE)
        case CompanyType.TRANSAMERICA:
          return findAgreementInfo(InvestmentType.TRANSAMERICA)
        case CompanyType.PENSION:
          return findAgreementInfo(InvestmentType.VALIC_8)
        case CompanyType.COREBRIDGE:
          return findAgreementInfo(InvestmentType.VALIC_6)
        default:
          if (valicFiveInvestmentTypes.includes(currentUser.accountName)) {
            return findAgreementInfo(InvestmentType.VALIC_5)
          } else if (lincolnInvestmentTypes.includes(currentUser.accountName)) {
            return findAgreementInfo(InvestmentType.AMERITAS)
          } else {
            return InvestmentType.DEFAULT
          }
      }
    }
  }
  const selectedAgreementType = getAgreementType()
  const handleSubmit = async ({
    signingMethod,
    planDetails,
    planSponsor,
    planSponsorDetails,
    recordkeeper,
    recordkeeperDetails,
    tpaConsultant,
    tpaConsultantDetails,
  }) => {
    const planSponsorObject = createAudienceObject({
      entityType: AudienceType.PLAN_SPONSOR,
      entity: planSponsorDetails,
      selectedValue: planSponsor,
    })
    const recordkeeperObject = createAudienceObject({
      entityType: AudienceType.RECORD_KEEPER,
      entity: recordkeeperDetails,
      selectedValue: recordkeeper,
    })
    const tpaConsultantObject = createAudienceObject({
      entityType: tpaConsultantDetails.company.type,
      entity: tpaConsultantDetails,
      selectedValue: tpaConsultant,
    })

    const initialInvestmentOption = find(
      availableInitialInvestments,
      (agreement) => agreement.templateId === planDetails.initialInvestment
    )

    const formData = {
      planName: planDetails.name,
      planNumber: planDetails.planNumber,
      planEIN: planDetails.ein,
      planType: planDetails.type,
      planStatus: planDetails.status,
      planIdNumber: planDetails.providerPlanIdNumber,
      serviceAgreementID: planDetails.serviceAgreementID,
      agreementType: selectedAgreementType
        ? selectedAgreementType.templateName
        : initialInvestmentOption?.templateName,
      agreementTemplateID: selectedAgreementType
        ? selectedAgreementType.templateId
        : planDetails.initialInvestment,
      requestContactID: currentUser.contactID,
      company: compact([
        planSponsorObject,
        recordkeeperObject,
        tpaConsultantObject,
      ]),
    }

    if (!signingMethod && isPlanFiduciary) {
      signingMethod = signingMethodOptions.find(
        (method) => method.key === SigningMethod.E_SIGN_RSP
      ).value
    }

    try {
      await submitServicesAgreementForm({
        planAgreementId: planDetails.serviceAgreementID,
        signingMethod,
        formData,
      })
      setShouldTriggerAllServicesAgreementsFetch(true)
      history.push({
        pathname: Path.BD_SERVICES_AGREEMENTS,
        state: {
          ignorePrompt: true, // No need to warn the user about leaving the flow
        },
      })
      flashSuccessMessageHandler(
        generateSuccessMessage({
          recordkeeperName: recordkeeperObject?.name,
          signingMethod,
        }),
        { timeout: FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT }
      )
    } catch (e) {
      if (e.status === 504) {
        history.push({
          pathname: Path.BD_SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true,
            showTimeoutModal: true,
          },
        })
      } else {
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
      }
    }
  }

  return (
    <>
      {isSubmittingServicesAgreementForm && <FullPageSpinner showBackground />}
      <div className="card full-height complete-services-agreement-view">
        <div className="complete-services-agreement-form-container">
          <div className="form-masthead">
            <h2>Let’s capture your services agreement details</h2>
          </div>
          <CompletePlanAgreementForm
            initialValues={mergeAll([
              completeServicesAgreementForm,
              {
                planSponsorDetails: existingPlanSponsorObject,
                recordkeeperDetails: existingRecordkeeperObject,
                tpaConsultantDetails: existingTpaConsultantObject,
              },
            ])}
            handleSubmit={handleSubmit}
            planSponsors={
              allowPlanSponsorSelection ? transformedPlanSponsors : []
            }
            recordkeepers={
              allowRecordkeeperSelection ? transformedRecordkeepers : []
            }
            tpasAndConsultants={
              allowTpaConsultantSelection ? tpasAndConsultants : []
            }
            availableInitialInvestments={availableInitialInvestments}
            allowRecordkeeperSelection={allowRecordkeeperSelection}
            allowTpaConsultantSelection={allowTpaConsultantSelection}
            isPlanFiduciary={isPlanFiduciary}
            currentUser={currentUser}
          />
        </div>
      </div>
    </>
  )
}

CompletePlanAgreementView.propTypes = propTypes
CompletePlanAgreementView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    transformedPlanSponsors: selectors.transformedPlanSponsors(state),
    transformedRecordkeepers: selectors.transformedRecordkeepers(state),
    transformedTpas: selectors.transformedTpas(state),
    transformedConsultants: selectors.transformedConsultants(state),
    availableInitialInvestments:
      productSelectors.availableInitialInvestments(state),
    completeServicesAgreementForm:
      selectors.completeServicesAgreementForm(state),
    currentUser: globalSelectors.currentUser(state),
    isSubmittingServicesAgreementForm: apiSelectors.isLoading(
      state,
      apiActions.submitServicesAgreementForm
    ),
  }
}

const mapDispatchToProps = {
  flashSuccessMessageHandler: flashSuccessMessage,
  flashErrorMessageHandler: flashErrorMessage,
  fetchAvailableInitialInvestments: apiActions.fetchAvailableInitialInvestments,
  fetchPlanSponsors: apiActions.fetchPlanSponsors,
  fetchRecordkeepers: apiActions.fetchRecordkeepers,
  fetchTpas: apiActions.fetchTpas,
  fetchConsultants: apiActions.fetchConsultants,
  submitServicesAgreementForm: apiActions.submitServicesAgreementForm,
  setShouldTriggerAllServicesAgreementsFetch:
    productsActions.setShouldTriggerAllServicesAgreementsFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CompletePlanAgreementView
)
