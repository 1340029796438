import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import {
  ParticipantFileUploadStage,
  CreateNewServicesAgreementStage,
  Path,
  BDActionItems,
  PARTICIPANT_FILE_UPLOAD_STAGES,
  CREATE_NEW_SERVICES_AGREEMENT_STAGES,
  isBenefitDistributionsAgreementsEnabled,
} from 'config/portal'

const propTypes = {
  match: PropTypes.object.isRequired,
}
const defaultProps = {}

const PARTICIPANT_FILE_UPLOAD_HEADER = BDActionItems.INITIATE_DISTRIBUTION

function Routes({ match: { path } }) {
  // window.appEventData.pop()
  // var appEventData = window.appEventData || []
  // appEventData.push({
  //   event: 'Page Load Completed',
  // })
  return (
    <Switch>
      {isBenefitDistributionsAgreementsEnabled() && (
        <Route
          path={path + '/add-new-services-agreement'}
          render={() => (
            <Layout
              title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
              subject="Services Agreement"
              currentStage={
                CreateNewServicesAgreementStage.ADD_NEW_SERVICES_AGREEMENT
              }
              stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
            >
              <Views.AddNewServicesAgreementView />
            </Layout>
          )}
        />
      )}
      <Route
        path={path + '/select-your-information'}
        render={() => (
          <Layout
            title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
            subject="Services Agreement"
            currentStage={
              CreateNewServicesAgreementStage.SELECT_COMPANY_INFORMATION
            }
            stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
          >
            <Views.SelectCompanyInformationView />
          </Layout>
        )}
      />
      <Route
        path={path + '/complete-services-agreement'}
        render={() => (
          <Layout
            title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
            subject="Services Agreement"
            currentStage={
              CreateNewServicesAgreementStage.AGREEMENT_DETAILS_AND_SUBMIT
            }
            stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
          >
            <Views.CompletePlanAgreementView />
          </Layout>
        )}
      />
      <Route
        path={path + '/upload-file'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStage.participantFileUpload}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES}
          >
            <Views.UploadParticipantFileView />
          </Layout>
        )}
      />
      <Route
        path={path + '/plan-mapping'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStage.planMapping}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES}
          >
            <Views.PlanMappingView />
          </Layout>
        )}
      />
      <Route
        path={path + '/plan-confirmation'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStage.planMapping}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES}
          >
            <Views.PlanConfirmationView />
          </Layout>
        )}
      />
      <Route
        path={path + '/review-and-submit-participant-data'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStage.reviewAndSubmit}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES}
          >
            <Views.ReviewAndSubmitView />
          </Layout>
        )}
      />
      <Redirect path="*" to={Path.BD_SERVICES_AGREEMENTS} />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
