import { join, escapeRegExp, find, mapKeys, cloneDeep, isEmpty } from 'lodash'
import { ReasonForDiscard } from 'config/automatic-rollovers'
import { SEARCH_ORDER_PROHIBITED_COMPANIES } from 'config/search-services'

// Feature flag for manual Flatfile entry
export function isManualInputEnabled() {
  return process.env.ENABLE_MANUAL_INPUT
}

export const RecordTypes = {
  SEARCH: 'search',
  ARO_PARTICIPANT: 'automaticRolloversParticipant',
  BD_PARTICIPANT: 'benefitsDistributionParticipant',
  UCH_PARTICIPANT: 'uncashedChecksParticipant',
}

export const RecordNames = {
  [RecordTypes.SEARCH]: 'Search',
  [RecordTypes.ARO_PARTICIPANT]: 'Participant',
  [RecordTypes.BD_PARTICIPANT]: 'Participant',
  [RecordTypes.UCH_PARTICIPANT]: 'Participant',
}

export const IRA_AMOUNT = {
  TRADITIONAL_IRA_AMOUNT: 'traditionalIraAmount',
  TRADITIONAL_PRE_TAX: 'traditionalIraPreTaxAmount',
  TRADITIONAL_AFTER_TAX: 'traditionalIraAfterTaxAmount',
  ROTH: 'rothIraAmount',
}

// The new Fields for the BD flatfile
export const BD_TRSANCTION_INFO = {
  RECURRING_PAYMENT: 'recurringPayment',
  FREQUENCY: 'frequency',
  TRANSACTION_TYPE: 'transactionType',
  PAYMENT_INSTRUCTIONS: 'paymentInstructions',
  AMOUNT: 'amount',
  TPA_BD_PROCESSING_FEE: 'tpaBenefitDistributionProcessingFee',
  INSPIRA_BD_PROCESSING_FEE: 'inspiraBenefitDisbursementProcessingFee',
  DISTRIBUTION_AMOUNT: 'distributionAmount',
  WIRE_ACH_1: 'wireAch',
  ABA_ROUTING: 'abaRouting',
  WIRE_ACH_2: 'wireAch2',
  WIRE_ACH_3: 'wireAch3',
  FFC_NAME: 'ffcName',
  FFC_NUMBER: 'ffcNumber',
  ACCOUNT_TYPE: 'accountType',
  CHECK_PAYABLE_INFO: 'checkPayableInfo',
  ACCOUNT_NUMBER: 'accountNumber',
  ADDRESS_FOR_CHECK: 'addressForCheck',
  CITY_FOR_CHECK: 'cityForCheck',
  STATE_FOR_CHECK: 'stateForCheck',
  ZIP_FOR_CHECK: 'zipForCheck',
  GROSS_DIST: 'grossDistribution',
  TAXABLE_AMOUNT: 'taxableAmount',
  TAXABLE_AMOUNT_NOT_DET: 'taxableAmountNotDetermined',
  TOTAL_DISTRIBUTION: 'totalDistribution',
  CAPITAL_GAIN: 'capitalGain',
  FEDERAL_INCOME_TAX_W: 'federalIncomeTax',
  EMPLOYEE_CONTRIBUTIONS: 'employeeContributions',
  NET_APPRECIATION: 'netAppreciation',
  DISTRIBUTION_CODES: 'distributionCode',
  PERCENTAGE_TOTAL_DIST: 'percentageTotalDistribution',
  TOTAL_EMPLOYEE_CONTRIBUTIONS: 'totalEmployeeContributions',
  AMOUNT_ALLOCABLE: 'amountAllocable',
  FIRST_YEAR_OF_DESIG: 'firstYearContrib',
  STATE_TAX_W: 'stateTaxWithheld',
  PAYER_STATE_NUMBER: 'payerStateNumber',
}

const Labels = {
  RECORDKEEPER: 'Recordkeeper',
  THIRD_PARTY_ADMINISTRATOR: 'Third party administrator',
  PLAN_SPONSOR: 'Plan sponsor',
  COMPLETE_LEGAL_PLAN_NAME: 'Complete legal plan name',
  EMPLOYER_IDENTIFICATION_NUMBER: 'Employer Identification Number (EIN)',
  PLAN_EMPLOYER_IDENTIFICATION_NUMBER:
    'Plan Employer Identification Number (EIN)',
  THREE_DIGIT_PLAN_NUMBER: 'Three-digit plan number (PN)',
  PROVIDER_PLAN_ID_NUMBER: 'Provider plan ID number',
  FIRST_NAME: 'First name',
  MIDDLE_NAME: 'Middle name',
  LAST_NAME: 'Last name',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  CITY: 'City',
  STATE: 'State',
  ZIP_CODE: 'ZIP code',
  COUNTRY_CODE: 'Country code',
  DATE_OF_BIRTH: 'Date of birth',
  SSN: 'SSN',
  EMAIL_ADDRESS: 'Email address',
  TRADITIONAL_IRA_PRE_TAX_AMOUNT: 'Traditional IRA pre-tax amount',
  TRADITIONAL_IRA_AFTER_TAX_AMOUNT: 'Traditional IRA after-tax amount',
  ROTH_IRA_AMOUNT: 'Roth IRA amount',
  PLAN_FIDUCIARY_NAME: 'Plan fiduciary (entity name)',
  PARTICIPANT_SSN: 'Participant SSN',
  PARTICIPANT_FIRST_NAME: 'Participant first name',
  PARTICIPANT_MIDDLE_NAME: 'Participant middle name',
  PARTICIPANT_LAST_NAME: 'Participant last name',
  PARTICIPANT_ADDRESS_1: 'Participant address line 1',
  PARTICIPANT_ADDRESS_2: 'Participant address line 2',
  PARTICIPANT_ADDRESS_3: 'Participant address line 3',
  PARTICIPANT_CITY: 'Participant city',
  PARTICIPANT_STATE: 'Participant state',
  PARTICIPANT_ZIP: 'Participant ZIP code',
  PARTICIPANT_DOB: 'Participant DOB',
  PARTICIPANT_DOD: 'Participant DOD',
  PHONE_NUMBER: 'Phone number',
  MISCELLANEOUS_1: 'Miscellaneous 1',
  MISCELLANEOUS_2: 'Miscellaneous 2',

  //Below are the feilds specific to BD flatfile
  RECURRING_PAYMENT_FOR_BD: 'Recurring payment (Y/N)',
  FREQUENCY: 'Frequency (M, Q, S, A, or N/A)',
  TRANSACTION_TYPE_FOR_BD:
    'Transaction type (check, wire, ACH, check/overnight delivery)',
  PAYMENT_INSTRUCTIONS_FOR_BD: 'Payment instructions',
  AMOUNT: 'Amount',
  TPA_BD_PROCESSING_FEE: 'Less: TPA benefit distribution processing fee',
  INSPIRA_BD_PROCESSING_FEE:
    'Less: Inspira Financial benefit distribution processing fee',
  DISTRIBUTION_AMOUNT: 'Distribution amount',
  WORK_FOR: 'Work for',
  REASON: 'Reason',
  WIRE_ACH_1: 'Wire/ACH receiving bank',
  ABA_ROUTING: 'ABA routing number',
  WIRE_ACH_2: 'Wire/ACH receiving account name',
  WIRE_ACH_3: 'Wire/ACH receiving account number',
  FFC_NAME: 'FFC name',
  FFC_NUMBER: 'FFC number',
  ACCOUNT_TYPE: 'Account type (checking or savings)',
  CHECK_PAYABLE_INFO: 'Check payable information (for check)',
  ACCOUNT_NUMBER: 'Plan number/account number (for check)',
  ADDRESS_FOR_CHECK: 'Address line 1 ( for check)',
  CITY_FOR_CHECK: 'City (for check)',
  STATE_FOR_CHECK: 'State (for check)',
  ZIP_FOR_CHECK: 'ZIP code (for check)',
  GROSS_DIST: 'Gross distribution (Box 1)',
  TAXABLE_AMOUNT: 'Taxable amount (Box2a)',
  TAXABLE_AMOUNT_NOT_DET: 'Taxable amount not determined (Box 2b/Null or "X")',
  TOTAL_DISTRIBUTION: 'Total distribution (Box 2b/Null or "X")',
  CAPITAL_GAIN: 'Capital gain (Box 3)',
  FEDERAL_INCOME_TAX_W: 'Federal income tax withheld (Box 4) - percent (%)',
  EMPLOYEE_CONTRIBUTIONS:
    'Employee contributions/designated Roth contributions or insurance premiums (Box 5)',
  NET_APPRECIATION:
    "Net unrealized appreciation in employer's securities (Box 6)",
  DISTRIBUTION_CODES: 'Distribution code(s) (Box 7)',
  PERCENTAGE_TOTAL_DIST: 'Your percentage of total distribution (Box 9a)',
  TOTAL_EMPLOYEE_CONTRIBUTIONS: 'Total employee contributions (Box 9b)',
  AMOUNT_ALLOCABLE: 'Amount allocable to IRR within 5 years (Box 10)',
  FIRST_YEAR_OF_DESIG: '1st year of desig. Roth contrib. (Box 11)',
  STATE_TAX_W:
    'State tax withheld (Box 12) - percent (%) (Note: mandatory states only)',
  PAYER_STATE_NUMBER:
    "State/payer's state no. (Box 13) (Note: mandatory states only)",

  //Below are the fields specific to UncashedChecks//
  TRADITIONAL_IRA_AMOUNT: 'Traditional IRA amount',
  DATE_ORIGIONAL_DISTRIBUTION: 'Date of original distribution',
  TAX_WITHHELD: 'Was tax withheld? Y/N',
}
const getColumnSize = (name) => {
  if (name.length <= 18) return 1
  return name.length / 18
}

const getColumnWidth = (name) => {
  if (name.length <= 18) return 'm'
  if (name.length > 50) return 'xl'
  return 'l'
}

const createMaxCharValidator = (limit) => ({
  validate: 'regex_excludes',
  regex: `^.{${limit + 1},}$`,
  error: `Maximum length exceeded - must be ${limit} characters or less`,
})

const validateEINRegex = () => ({
  invalidEINFormat: {
    regex: '^[0-9]{2}-[0-9]{7}$',
    error: 'Format must be: XX-XXXXXXX',
  },
  invalidEIN: {
    regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
    error: 'Invalid EIN',
  },
})

const validateSSNRegex = () => ({
  regex: additionalInvalidSsnRegex,
  error: 'Given SSN is not valid',
})

const validatePlanNumber = () => ({
  invalidCharacters: {
    regex: '^(\\D)',
    error: 'Invalid characters used',
  },
  mustBeThreeDigits: {
    regex: '^(\\d{3})$',
    error: 'Must be three digits',
  },
})

const createNamePrefixValidator = () => ({
  validate: 'regex_excludes',
  regex: `^(${namePrefixAbbreviationRegex}|${namePrefixRegex})`,
  regexFlags: { ignoreCase: true },
  error: 'Do not include name prefixes',
})

const createNotApplicableValidator = () => ({
  validate: 'regex_excludes',
  regex: notApplicableRegex,
  regexFlags: { ignoreCase: true },
  error: 'Required',
})

const createSearchOrderProhibitedCompaniesValidator = () => ({
  validate: 'regex_excludes',
  regex: searchOrderProhibitedCompaniesRegex,
  regexFlags: { ignoreCase: true },
  error: ReasonForDiscard.PROHIBITED_COMPANY,
})

const ADDITIONAL_INVALID_SSN = ['123-45-6789', '111-11-1111', '333-33-3333']
const NAME_PREFIX_ABBREVIATIONS = ['Mr', 'Ms', 'Mrs']
const NAME_PREFIXES = ['Mister', 'Miss', 'Missus']
const NOT_APPLICABLE_VARIATIONS = ['Not Applicable', 'N/A', 'NA', 'N\\.A\\.']

// names cannot begin with a prefix (or be a prefix) but names like Mrittika, Msrah should pass the validation
const namePrefixAbbreviationRegex = NAME_PREFIX_ABBREVIATIONS.map(
  (prefix) => `${prefix}([ .,]|$)`
).join('|')
const namePrefixRegex = NAME_PREFIXES.map((prefix) => `${prefix}([ ]|$)`).join(
  '|'
)

const notApplicableRegex = NOT_APPLICABLE_VARIATIONS.map(
  (notApplicable) => `(^${notApplicable}$)`
).join('|')

const additionalInvalidSsnRegex = ADDITIONAL_INVALID_SSN.map(
  (invalidSsn) => `(^${invalidSsn}$)`
).join('|')

export const searchOrderProhibitedCompaniesRegex =
  SEARCH_ORDER_PROHIBITED_COMPANIES.map(
    (company) => `(^${escapeRegExp(company)}$)`
  ).join('|')

const AcceptableFileExtensions = {
  [RecordTypes.SEARCH]: ['.csv'],
  [RecordTypes.ARO_PARTICIPANT]: ['.csv', '.xls', '.xlsx'],
  [RecordTypes.BD_PARTICIPANT]: ['.csv', '.xls', '.xlsx'],
  [RecordTypes.UCH_PARTICIPANT]: ['.csv', '.xls', '.xlsx'],
}
export const UploadInstructions = {
  [RecordTypes.SEARCH]:
    'Important reminders: 1) Submit your information by uploading your data in a .csv file or manually filling out the form below. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Any highlighted fields that aren’t resolved won’t be included in your submission.',
  [RecordTypes.ARO_PARTICIPANT]: `Important reminders: 1) You can include participants from different retirement plans and/or plan sponsors. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Files cannot contain merged cells. 5) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
  [RecordTypes.BD_PARTICIPANT]: `Important reminders: 1) Submit your information by uploading your data in a .csv file or manually filling out the form below. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting.  4) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
  [RecordTypes.UCH_PARTICIPANT]: `Important reminders: 1) You can include participants from different retirement plans and/or plan sponsors. 2) Fields marked with an asterisk* are required. 3) You’ll be able to review your data and make changes before submitting. 4) Files cannot contain merged cells. 5) Any highlighted fields that aren’t resolved won’t be included in your submission.`,
}

const FlatfileSchema = {
  [RecordTypes.ARO_PARTICIPANT]: [
    {
      key: 'recordkeeper',
      label: Labels.RECORDKEEPER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'recordKeeper',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Recordkeeper. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.RECORDKEEPER),
      },
    },
    {
      key: 'tpa',
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'tpa',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error:
                'Please enter a valid Third Party Administrator. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THIRD_PARTY_ADMINISTRATOR),
      },
    },
    {
      key: 'planSponsor',
      label: Labels.PLAN_SPONSOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planSponsor',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PLAN_SPONSOR),
      },
    },
    {
      key: 'planName',
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planName',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Name. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(80),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COMPLETE_LEGAL_PLAN_NAME),
      },
    },
    {
      key: 'planEin',
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planEin',
          config: validateEINRegex(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
      },
    },
    {
      key: 'planNumber',
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planNumber',
          config: validatePlanNumber(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THREE_DIGIT_PLAN_NUMBER),
      },
    },
    {
      key: 'planIdNumber',
      label: Labels.PROVIDER_PLAN_ID_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planIdNumber',
          config: {
            createMaxCharValidator: createMaxCharValidator(30),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PROVIDER_PLAN_ID_NUMBER),
      },
    },
    {
      key: 'firstName',
      label: Labels.FIRST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'firstName',
          config: {
            createMaxCharValidator: createMaxCharValidator(40),
            checkNamePrefix: createNamePrefixValidator(),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FIRST_NAME),
      },
    },
    {
      key: 'middleName',
      label: Labels.MIDDLE_NAME,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'middleName',
          config: {
            createMaxCharValidator: createMaxCharValidator(20),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.MIDDLE_NAME),
      },
    },
    {
      key: 'lastName',
      label: Labels.LAST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'lastName',
          config: {
            createMaxCharValidator: createMaxCharValidator(60),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.LAST_NAME),
      },
    },
    {
      key: 'addressLine1',
      label: Labels.ADDRESS_LINE_1,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'addressLine1',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_1),
      },
    },
    {
      key: 'addressLine2',
      label: Labels.ADDRESS_LINE_2,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'addressLine2',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_2),
      },
    },
    {
      key: 'city',
      label: Labels.CITY,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'city',
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.CITY),
      },
    },
    {
      key: 'state',
      label: Labels.STATE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.STATE),
      },
    },
    {
      key: 'zipCode',
      label: Labels.ZIP_CODE,
      type: 'string',
      constraints: [],
      appearance: {
        size: getColumnWidth(Labels.ZIP_CODE),
      },
    },
    {
      key: 'countryCode',
      label: Labels.COUNTRY_CODE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COUNTRY_CODE),
      },
    },
    {
      key: 'dob',
      label: Labels.DATE_OF_BIRTH,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.DATE_OF_BIRTH),
      },
    },
    {
      key: 'ssn',
      label: Labels.SSN,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'ssn',
          config: {
            ssnValidityCheck: validateSSNRegex(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.SSN),
      },
    },
    {
      key: 'emailAddress',
      label: Labels.EMAIL_ADDRESS,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'emailAddress',
          config: {
            createMaxCharValidator: createMaxCharValidator(50),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMAIL_ADDRESS),
      },
    },
    {
      key: 'traditionalIraPreTaxAmount',
      label: Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'traditionalIraPreTaxAmount',
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT),
      },
    },
    {
      key: 'traditionalIraAfterTaxAmount',
      label: Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'traditionalIraAfterTaxAmount',
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT),
      },
    },
    {
      key: IRA_AMOUNT.ROTH,
      label: Labels.ROTH_IRA_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'rothIraAmount',
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ROTH_IRA_AMOUNT),
      },
    },
  ],
  [RecordTypes.BD_PARTICIPANT]: [
    {
      key: 'recordkeeper',
      label: Labels.RECORDKEEPER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'recordKeeper',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Recordkeeper. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.RECORDKEEPER),
      },
    },
    {
      key: 'tpa',
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'tpa',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error:
                'Please enter a valid Third Party Administrator. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THIRD_PARTY_ADMINISTRATOR),
      },
    },
    {
      key: 'planSponsor',
      label: Labels.PLAN_SPONSOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planSponsor',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PLAN_SPONSOR),
      },
    },
    {
      key: 'planName',
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planName',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Name. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(80),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COMPLETE_LEGAL_PLAN_NAME),
      },
    },
    {
      key: 'planEin',
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planEin',
          config: validateEINRegex(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
      },
    },
    {
      key: 'planNumber',
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planNumber',
          config: validatePlanNumber(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THREE_DIGIT_PLAN_NUMBER),
      },
    },
    {
      key: 'firstName',
      label: Labels.FIRST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'firstName',
          config: {
            createMaxCharValidator: createMaxCharValidator(40),
            checkNamePrefix: createNamePrefixValidator(),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FIRST_NAME),
      },
    },
    {
      key: 'middleName',
      label: Labels.MIDDLE_NAME,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'middleName',
          config: {
            createMaxCharValidator: createMaxCharValidator(20),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.MIDDLE_NAME),
      },
    },
    {
      key: 'lastName',
      label: Labels.LAST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'lastName',
          config: {
            createMaxCharValidator: createMaxCharValidator(60),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.LAST_NAME),
      },
    },
    {
      key: 'addressLine1',
      label: Labels.ADDRESS_LINE_1,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'addressLine1',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_1),
      },
    },
    {
      key: 'addressLine2',
      label: Labels.ADDRESS_LINE_2,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'addressLine2',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_2),
      },
    },
    {
      key: 'city',
      label: Labels.CITY,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'city',
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.CITY),
      },
    },
    {
      key: 'state',
      label: Labels.STATE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.STATE),
      },
    },
    {
      key: 'zipCode',
      label: Labels.ZIP_CODE,
      type: 'string',
      constraints: [],
      appearance: {
        size: getColumnWidth(Labels.ZIP_CODE),
      },
    },
    {
      key: 'countryCode',
      label: Labels.COUNTRY_CODE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COUNTRY_CODE),
      },
    },
    {
      key: 'phone',
      label: Labels.PHONE_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'phone',
          config: {
            phoneNumber: {
              regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
              error:
                'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PHONE_NUMBER),
      },
    },
    {
      key: 'dob',
      label: Labels.DATE_OF_BIRTH,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.DATE_OF_BIRTH),
      },
    },
    {
      key: 'ssn',
      label: Labels.SSN,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'ssn',
          config: {
            ssnValidityCheck: validateSSNRegex(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.SSN),
      },
    },
    {
      key: 'emailAddress',
      label: Labels.EMAIL_ADDRESS,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'emailAddress',
          config: {
            createMaxCharValidator: createMaxCharValidator(50),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMAIL_ADDRESS),
      },
    },
    {
      key: BD_TRSANCTION_INFO.RECURRING_PAYMENT,
      label: Labels.RECURRING_PAYMENT_FOR_BD,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.RECURRING_PAYMENT,
          config: {
            recurringPayment: {
              regex: '^(?:Y|N)$',
              error: 'Must be Y or N',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.RECURRING_PAYMENT_FOR_BD),
      },
    },
    {
      key: BD_TRSANCTION_INFO.FREQUENCY,
      label: Labels.FREQUENCY,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.FREQUENCY,
          config: {
            // required when recurring value is validated in bdParticipantListener.js
            frequency: {
              regex: '^(?:M|Q|S|A|N/A)$',
              error: 'Must be M, Q, S, A, or N/A',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FREQUENCY),
      },
    },
    {
      key: BD_TRSANCTION_INFO.TRANSACTION_TYPE,
      label: Labels.TRANSACTION_TYPE_FOR_BD,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.TRANSACTION_TYPE,
          config: {
            transactionType: {
              regex: '^(?:check|wire|ACH|check/overnight delivery)$',
              error: 'Must be check, wire, ACH, or check/overnight delivery',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TRANSACTION_TYPE_FOR_BD),
      },
    },
    {
      key: BD_TRSANCTION_INFO.PAYMENT_INSTRUCTIONS,
      label: Labels.PAYMENT_INSTRUCTIONS_FOR_BD,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.PAYMENT_INSTRUCTIONS,
          config: {
            paymentInstructions: {
              regex: '^(?:direct to participant|direct rollover)$',
              error: `Must be 'direct to participant' or 'direct rollover'`,
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PAYMENT_INSTRUCTIONS_FOR_BD),
      },
    },
    {
      key: BD_TRSANCTION_INFO.AMOUNT,
      label: Labels.AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.AMOUNT,
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.AMOUNT),
      },
    },
    {
      key: BD_TRSANCTION_INFO.TPA_BD_PROCESSING_FEE,
      label: Labels.TPA_BD_PROCESSING_FEE,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.TPA_BD_PROCESSING_FEE,
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TPA_BD_PROCESSING_FEE),
      },
    },
    {
      label: Labels.INSPIRA_BD_PROCESSING_FEE,
      key: BD_TRSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE,
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.INSPIRA_BD_PROCESSING_FEE),
      },
    },
    {
      label: Labels.DISTRIBUTION_AMOUNT,
      key: BD_TRSANCTION_INFO.DISTRIBUTION_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.DISTRIBUTION_AMOUNT,
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.DISTRIBUTION_AMOUNT),
      },
    },
    {
      label: Labels.WIRE_ACH_1,
      key: BD_TRSANCTION_INFO.WIRE_ACH_1,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.WIRE_ACH_1,
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.WIRE_ACH_1),
      },
    },
    {
      label: Labels.ABA_ROUTING,
      key: BD_TRSANCTION_INFO.ABA_ROUTING,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.ABA_ROUTING,
          config: {
            abaRouting: {
              regex: '^[0-9]{9}$',
              error:
                'Nine-digit number only, social security number (SSN) unaccepted',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ABA_ROUTING),
      },
    },
    {
      label: Labels.WIRE_ACH_2,
      key: BD_TRSANCTION_INFO.WIRE_ACH_2,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.WIRE_ACH_2,
          config: {
            wireAch2: {
              regex: '^(?=.*[0-9]).+$',
              error: 'String must have at least one numeric character',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.WIRE_ACH_2),
      },
    },
    {
      label: Labels.WIRE_ACH_3,
      key: BD_TRSANCTION_INFO.WIRE_ACH_3,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.WIRE_ACH_3,
          config: {
            wireAch3: {
              regex: '^[0-9]{9}$',
              error: 'Nine-digit number only',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.WIRE_ACH_3),
      },
    },
    {
      label: Labels.FFC_NAME,
      key: BD_TRSANCTION_INFO.FFC_NAME,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.FFC_NAME,
          config: {
            ffcName: {
              regex: '^.*[A-Za-z].*$',
              error: 'String must have at least one alpha character',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FFC_NAME),
      },
    },
    {
      label: Labels.FFC_NUMBER,
      key: BD_TRSANCTION_INFO.FFC_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.FFC_NUMBER,
          config: {
            ffcNumber: {
              regex: '^(?=.*[0-9]).+$',
              error: 'Must have at least one numeric character.',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FFC_NUMBER),
      },
    },
    {
      label: Labels.ACCOUNT_TYPE,
      key: BD_TRSANCTION_INFO.ACCOUNT_TYPE,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.ACCOUNT_TYPE,
          config: {
            accountType: {
              regex: '^(?:checking|savings)$',
              error: `Must be 'checking' or 'savings'`,
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ACCOUNT_TYPE),
      },
    },
    {
      label: Labels.CHECK_PAYABLE_INFO,
      key: BD_TRSANCTION_INFO.CHECK_PAYABLE_INFO,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.CHECK_PAYABLE_INFO,
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.CHECK_PAYABLE_INFO),
      },
    },
    {
      label: Labels.ACCOUNT_NUMBER,
      key: BD_TRSANCTION_INFO.ACCOUNT_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.ACCOUNT_NUMBER,
          config: {
            accountNumber: {
              regex: '^(?=.*[0-9]).+$',
              error: 'Must have at least one numeric character',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ACCOUNT_NUMBER),
      },
    },
    {
      label: Labels.ADDRESS_FOR_CHECK,
      key: BD_TRSANCTION_INFO.ADDRESS_FOR_CHECK,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.ADDRESS_FOR_CHECK,
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_FOR_CHECK),
      },
    },
    {
      label: Labels.CITY_FOR_CHECK,
      key: BD_TRSANCTION_INFO.CITY_FOR_CHECK,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.CITY_FOR_CHECK,
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.CITY_FOR_CHECK),
      },
    },
    {
      label: Labels.STATE_FOR_CHECK,
      key: BD_TRSANCTION_INFO.STATE_FOR_CHECK,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.STATE_FOR_CHECK,
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.STATE_FOR_CHECK),
      },
    },
    {
      label: Labels.ZIP_FOR_CHECK,
      key: BD_TRSANCTION_INFO.ZIP_FOR_CHECK,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: BD_TRSANCTION_INFO.ZIP_FOR_CHECK,
          config: {
            createMaxCharValidator: createMaxCharValidator(10),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ZIP_FOR_CHECK),
      },
    },
  ],
  [RecordTypes.SEARCH]: [
    {
      label: Labels.PLAN_FIDUCIARY_NAME,
      key: 'planSponsorName',
      type: 'string',
      constraints: [
        { type: 'required' },
        {
          type: 'external',
          validator: 'planSponsorName',
          config: {
            prohibitedCompanies:
              createSearchOrderProhibitedCompaniesValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PLAN_FIDUCIARY_NAME),
      },
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      type: 'string',
      constraints: [{ type: 'required' }],
      appearance: {
        size: getColumnWidth(Labels.COMPLETE_LEGAL_PLAN_NAME),
      },
    },
    {
      label: Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planEin',
          config: validateEINRegex(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER),
      },
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planNumber',
          config: validatePlanNumber(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THREE_DIGIT_PLAN_NUMBER),
      },
    },
    {
      label: Labels.PARTICIPANT_SSN,
      key: 'ssn',
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'ssn',
          config: {
            ssnValidityCheck: {
              validate: 'regex_excludes',
              regex: additionalInvalidSsnRegex,
              error: 'SSN is not valid',
            },
            formatCheck: {
              validate: 'regex_matches',
              regex: '^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$',
              error: 'Format must be XXX-XX-XXXX',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_SSN),
      },
    },
    {
      label: Labels.PARTICIPANT_FIRST_NAME,
      key: 'firstName',
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'firstName',
          config: {
            checkNamePrefix: createNamePrefixValidator(),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_FIRST_NAME),
      },
    },
    {
      label: Labels.PARTICIPANT_MIDDLE_NAME,
      key: 'middleName',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_MIDDLE_NAME),
      },
    },
    {
      label: Labels.PARTICIPANT_LAST_NAME,
      key: 'lastName',
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'lastName',
          config: {
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_LAST_NAME),
      },
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_1,
      key: 'address1',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_ADDRESS_1),
      },
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_2,
      key: 'address2',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_ADDRESS_2),
      },
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_3,
      key: 'address3',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_ADDRESS_3),
      },
    },
    {
      label: Labels.PARTICIPANT_CITY,
      key: 'city',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_CITY),
      },
    },
    {
      label: Labels.PARTICIPANT_STATE,
      key: 'state',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_STATE),
      },
    },
    {
      label: Labels.PARTICIPANT_ZIP,
      key: 'zip',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_ZIP),
      },
    },
    {
      label: Labels.PARTICIPANT_DOB,
      key: 'dob',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_DOB),
      },
    },
    {
      label: Labels.PARTICIPANT_DOD,
      key: 'dod',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.PARTICIPANT_DOD),
      },
    },
    {
      label: Labels.PHONE_NUMBER,
      key: 'phone',
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'phone',
          config: {
            phoneNumber: {
              regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
              error:
                'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PHONE_NUMBER),
      },
    },
    {
      label: Labels.WORK_FOR,
      key: 'workFor',
      type: 'string',
      validators: [],
      appearance: {
        size: getColumnWidth(Labels.WORK_FOR),
      },
    },
    {
      label: Labels.REASON,
      key: 'reason',
      type: 'string',
      appearance: {
        size: getColumnWidth(Labels.REASON),
      },
    },
  ],
  [RecordTypes.UCH_PARTICIPANT]: [
    {
      key: 'recordkeeper',
      label: Labels.RECORDKEEPER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'recordKeeper',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Recordkeeper. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.RECORDKEEPER),
      },
    },
    {
      key: 'tpa',
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'tpa',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error:
                'Please enter a valid Third Party Administrator. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THIRD_PARTY_ADMINISTRATOR),
      },
    },
    {
      key: 'planSponsor',
      label: Labels.PLAN_SPONSOR,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planSponsor',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(60),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PLAN_SPONSOR),
      },
    },
    {
      key: 'planName',
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planName',
          config: {
            noSlashRegex: {
              regex: '\\/|\\\\',
              error: 'Please enter a valid Plan Name. No slashes allowed.',
            },
            createMaxCharValidator: createMaxCharValidator(80),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COMPLETE_LEGAL_PLAN_NAME),
      },
    },
    {
      key: 'planEin',
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'planEin',
          config: validateEINRegex(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
      },
    },
    {
      key: 'planNumber',
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planNumber',
          config: validatePlanNumber(),
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.THREE_DIGIT_PLAN_NUMBER),
      },
    },
    {
      key: 'planIdNumber',
      label: Labels.PROVIDER_PLAN_ID_NUMBER,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'planIdNumber',
          config: {
            createMaxCharValidator: createMaxCharValidator(30),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.PROVIDER_PLAN_ID_NUMBER),
      },
    },
    {
      key: 'firstName',
      label: Labels.FIRST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'firstName',
          config: {
            createMaxCharValidator: createMaxCharValidator(40),
            checkNamePrefix: createNamePrefixValidator(),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.FIRST_NAME),
      },
    },
    {
      key: 'middleName',
      label: Labels.MIDDLE_NAME,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'middleName',
          config: {
            createMaxCharValidator: createMaxCharValidator(20),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.MIDDLE_NAME),
      },
    },
    {
      key: 'lastName',
      label: Labels.LAST_NAME,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'lastName',
          config: {
            createMaxCharValidator: createMaxCharValidator(60),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.LAST_NAME),
      },
    },
    {
      key: 'addressLine1',
      label: Labels.ADDRESS_LINE_1,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'addressLine1',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
            checkNotApplicable: createNotApplicableValidator(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_1),
      },
    },
    {
      key: 'addressLine2',
      label: Labels.ADDRESS_LINE_2,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'addressLine2',
          config: {
            createMaxCharValidator: createMaxCharValidator(36),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ADDRESS_LINE_2),
      },
    },
    {
      key: 'city',
      label: Labels.CITY,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'city',
          config: {
            createMaxCharValidator: createMaxCharValidator(22),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.CITY),
      },
    },
    {
      key: 'state',
      label: Labels.STATE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.STATE),
      },
    },
    {
      key: 'zipCode',
      label: Labels.ZIP_CODE,
      type: 'string',
      constraints: [],
      appearance: {
        size: getColumnWidth(Labels.ZIP_CODE),
      },
    },
    {
      key: 'countryCode',
      label: Labels.COUNTRY_CODE,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.COUNTRY_CODE),
      },
    },
    {
      key: 'dob',
      label: Labels.DATE_OF_BIRTH,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.DATE_OF_BIRTH),
      },
    },
    {
      key: 'ssn',
      label: Labels.SSN,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
        {
          type: 'external',
          validator: 'ssn',
          config: {
            ssnValidityCheck: validateSSNRegex(),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.SSN),
      },
    },
    {
      key: 'emailAddress',
      label: Labels.EMAIL_ADDRESS,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'emailAddress',
          config: {
            createMaxCharValidator: createMaxCharValidator(50),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.EMAIL_ADDRESS),
      },
    },
    {
      key: IRA_AMOUNT.TRADITIONAL_IRA_AMOUNT,
      label: Labels.TRADITIONAL_IRA_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'traditionalIraAmount',
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TRADITIONAL_IRA_AMOUNT),
      },
    },
    {
      key: IRA_AMOUNT.ROTH,
      label: Labels.ROTH_IRA_AMOUNT,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'rothIraAmount',
          config: {
            createMaxCharValidator: createMaxCharValidator(14),
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.ROTH_IRA_AMOUNT),
      },
    },
    {
      key: 'dateOfOriginalDistribution',
      label: Labels.DATE_ORIGIONAL_DISTRIBUTION,
      type: 'string',
      constraints: [
        {
          type: 'required',
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.DATE_ORIGIONAL_DISTRIBUTION),
      },
    },
    {
      key: 'wasTaxWithheld',
      label: Labels.TAX_WITHHELD,
      type: 'string',
      constraints: [
        {
          type: 'external',
          validator: 'wasTaxWithheld',
          config: {
            wasTaxWithheld: {
              regex: '^(?:Y|N)$',
              error: 'Must be Y or N',
            },
          },
        },
      ],
      appearance: {
        size: getColumnWidth(Labels.TAX_WITHHELD),
      },
    },
  ],
}

const FlatfileFields = {
  [RecordTypes.SEARCH]: [
    {
      label: Labels.PLAN_FIDUCIARY_NAME,
      key: 'planSponsorName',
      validators: [
        { validate: 'required' },
        createSearchOrderProhibitedCompaniesValidator(),
      ],
      sizeHint: getColumnSize(Labels.PLAN_FIDUCIARY_NAME),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.PLAN_EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',

      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.PARTICIPANT_SSN,
      key: 'ssn',
      validators: [
        {
          validate: 'required',
        },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'SSN is not valid',
        },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$',
          error: 'Format must be XXX-XX-XXXX',
        },
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_SSN),
    },
    {
      label: Labels.PARTICIPANT_FIRST_NAME,
      key: 'firstName',
      validators: [
        {
          validate: 'required',
          error: 'Required',
        },
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_FIRST_NAME),
    },
    {
      label: Labels.PARTICIPANT_MIDDLE_NAME,
      key: 'middleName',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_MIDDLE_NAME),
    },
    {
      label: Labels.PARTICIPANT_LAST_NAME,
      key: 'lastName',
      validators: [
        {
          validate: 'required',
          error: 'Required',
        },
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.PARTICIPANT_LAST_NAME),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_1,
      key: 'address1',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_1),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_2,
      key: 'address2',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_2),
    },
    {
      label: Labels.PARTICIPANT_ADDRESS_3,
      key: 'address3',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ADDRESS_3),
    },
    {
      label: Labels.PARTICIPANT_CITY,
      key: 'city',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_CITY),
    },
    {
      label: Labels.PARTICIPANT_STATE,
      key: 'state',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_STATE),
    },
    {
      label: Labels.PARTICIPANT_ZIP,
      key: 'zip',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_ZIP),
    },
    {
      label: Labels.PARTICIPANT_DOB,
      key: 'dob',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_DOB),
    },
    {
      label: Labels.PARTICIPANT_DOD,
      key: 'dod',
      validators: [],
      sizeHint: getColumnSize(Labels.PARTICIPANT_DOD),
    },
    {
      label: Labels.PHONE_NUMBER,
      key: 'phone',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
          error:
            'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
        },
      ],
      sizeHint: getColumnSize(Labels.PHONE_NUMBER),
    },
    {
      label: Labels.WORK_FOR,
      key: 'workFor',
      validators: [],
      sizeHint: getColumnSize(Labels.WORK_FOR),
    },
    {
      label: Labels.REASON,
      key: 'reason',
      validators: [],
      sizeHint: getColumnSize(Labels.REASON),
    },
  ],
  [RecordTypes.ARO_PARTICIPANT]: [
    {
      label: Labels.RECORDKEEPER,
      key: 'recordkeeper',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required. Enter your company name if self-administering.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Recordkeeper. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.RECORDKEEPER),
    },
    {
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      key: 'tpa',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error:
            'Please enter a valid Third Party Administrator. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.THIRD_PARTY_ADMINISTRATOR),
    },
    {
      label: Labels.PLAN_SPONSOR,
      key: 'planSponsor',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
        },
        createMaxCharValidator(60),
      ],
      sizeHint: getColumnSize(Labels.PLAN_SPONSOR),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Name. No slashes allowed.',
        },
        createMaxCharValidator(80),
      ],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',
      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.PROVIDER_PLAN_ID_NUMBER,
      key: 'planIdNumber',
      validators: [createMaxCharValidator(30)],
      sizeHint: getColumnSize(Labels.PROVIDER_PLAN_ID_NUMBER),
    },
    {
      label: Labels.FIRST_NAME,
      key: 'firstName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(40),
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.FIRST_NAME),
    },
    {
      label: Labels.MIDDLE_NAME,
      key: 'middleName',
      validators: [createMaxCharValidator(20)],
      sizeHint: getColumnSize(Labels.MIDDLE_NAME),
    },
    {
      label: Labels.LAST_NAME,
      key: 'lastName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(60),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.LAST_NAME),
    },
    {
      label: Labels.ADDRESS_LINE_1,
      key: 'addressLine1',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(36),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_1),
    },
    {
      label: Labels.ADDRESS_LINE_2,
      key: 'addressLine2',
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_2),
    },
    {
      label: Labels.CITY,
      key: 'city',
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.CITY),
    },
    {
      label: Labels.STATE,
      key: 'state',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.STATE),
    },
    {
      label: Labels.ZIP_CODE,
      key: 'zipCode',
      validators: [],
      sizeHint: getColumnSize(Labels.ZIP_CODE),
    },
    {
      label: Labels.COUNTRY_CODE,
      key: 'countryCode',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COUNTRY_CODE),
    },
    {
      label: Labels.DATE_OF_BIRTH,
      key: 'dob',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.DATE_OF_BIRTH),
    },
    {
      label: Labels.SSN,
      key: 'ssn',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'Given SSN is not valid',
        },
      ],
      sizeHint: getColumnSize(Labels.SSN),
    },
    {
      label: Labels.EMAIL_ADDRESS,
      key: 'emailAddress',
      validators: [createMaxCharValidator(50)],
      sizeHint: getColumnSize(Labels.EMAIL_ADDRESS),
    },
    {
      label: Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT,
      key: IRA_AMOUNT.TRADITIONAL_PRE_TAX,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.TRADITIONAL_AFTER_TAX, IRA_AMOUNT.ROTH],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.TRADITIONAL_IRA_PRE_TAX_AMOUNT),
    },
    {
      label: Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT,
      key: IRA_AMOUNT.TRADITIONAL_AFTER_TAX,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.TRADITIONAL_PRE_TAX, IRA_AMOUNT.ROTH],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.TRADITIONAL_IRA_AFTER_TAX_AMOUNT),
    },
    {
      label: Labels.ROTH_IRA_AMOUNT,
      key: IRA_AMOUNT.ROTH,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [
            IRA_AMOUNT.TRADITIONAL_PRE_TAX,
            IRA_AMOUNT.TRADITIONAL_AFTER_TAX,
          ],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.ROTH_IRA_AMOUNT),
    },
  ],
  [RecordTypes.BD_PARTICIPANT]: [
    {
      label: Labels.RECORDKEEPER,
      key: 'recordkeeper',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required. Enter your company name if self-administering.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Recordkeeper. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.RECORDKEEPER),
    },
    {
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      key: 'tpa',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error:
            'Please enter a valid Third Party Administrator. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.THIRD_PARTY_ADMINISTRATOR),
    },
    {
      label: Labels.PLAN_SPONSOR,
      key: 'planSponsor',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
        },
        createMaxCharValidator(60),
      ],
      sizeHint: getColumnSize(Labels.PLAN_SPONSOR),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Name. No slashes allowed.',
        },
        createMaxCharValidator(80),
      ],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',
      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.FIRST_NAME,
      key: 'firstName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(40),
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.FIRST_NAME),
    },
    {
      label: Labels.MIDDLE_NAME,
      key: 'middleName',
      validators: [createMaxCharValidator(20)],
      sizeHint: getColumnSize(Labels.MIDDLE_NAME),
    },
    {
      label: Labels.LAST_NAME,
      key: 'lastName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(60),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.LAST_NAME),
    },
    {
      label: Labels.ADDRESS_LINE_1,
      key: 'addressLine1',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(36),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_1),
    },
    {
      label: Labels.ADDRESS_LINE_2,
      key: 'addressLine2',
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_2),
    },
    {
      label: Labels.CITY,
      key: 'city',
      validators: [{ validate: 'required' }, createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.CITY),
    },
    {
      label: Labels.STATE,
      key: 'state',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.STATE),
    },
    {
      label: Labels.ZIP_CODE,
      key: 'zipCode',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.ZIP_CODE),
    },
    {
      label: Labels.COUNTRY_CODE,
      key: 'countryCode',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COUNTRY_CODE),
    },
    {
      label: Labels.PHONE_NUMBER,
      key: 'phone',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(\\+|\\(|[0-9]){1}[0-9a-zA-Z ,\\.\\(\\)-]{2,35}$',
          error:
            'Must start with +, (, or a number and must follow a standard formatting convention. E.g., +01 (555) 243-0321 ext. 44',
        },
      ],
      sizeHint: getColumnSize(Labels.PHONE_NUMBER),
    },
    {
      label: Labels.DATE_OF_BIRTH,
      key: 'dob',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.DATE_OF_BIRTH),
    },
    {
      label: Labels.SSN,
      key: 'ssn',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'Given SSN is not valid',
        },
      ],
      sizeHint: getColumnSize(Labels.SSN),
    },
    {
      label: Labels.EMAIL_ADDRESS,
      key: 'emailAddress',
      validators: [createMaxCharValidator(50)],
      sizeHint: getColumnSize(Labels.EMAIL_ADDRESS),
    },
    {
      label: Labels.RECURRING_PAYMENT_FOR_BD,
      key: BD_TRSANCTION_INFO.RECURRING_PAYMENT,
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^(?:Y|N)$',
          error: 'Must be Y or N',
        },
      ],
      sizeHint: getColumnSize(Labels.RECURRING_PAYMENT_FOR_BD),
    },
    {
      label: Labels.FREQUENCY,
      key: BD_TRSANCTION_INFO.FREQUENCY,
      validators: [
        {
          validate: 'required_with_values',
          fieldValues: { recurringPayment: 'Y' },
          error: `Required only if Recurring Payment = 'Y'`,
        },
        {
          validate: 'regex_matches',
          regex: '^(?:M|Q|S|A|N/A)$',
          error: 'Must be M, Q, S, A, or N/A',
        },
      ],
      sizeHint: getColumnSize(Labels.FREQUENCY),
    },
    {
      label: Labels.TRANSACTION_TYPE_FOR_BD,
      key: BD_TRSANCTION_INFO.TRANSACTION_TYPE,
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^(?:check|wire|ACH|check/overnight delivery)$',
          error: 'Must be check, wire, ACH, or check/overnight delivery',
        },
      ],
      sizeHint: getColumnSize(Labels.TRANSACTION_TYPE_FOR_BD),
    },
    {
      label: Labels.PAYMENT_INSTRUCTIONS_FOR_BD,
      key: BD_TRSANCTION_INFO.PAYMENT_INSTRUCTIONS,
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^(?:direct to participant|direct rollover)$',
          error: `Must be 'direct to participant' or 'direct rollover'`,
        },
      ],
      sizeHint: getColumnSize(Labels.PAYMENT_INSTRUCTIONS_FOR_BD),
    },
    {
      label: Labels.AMOUNT,
      key: BD_TRSANCTION_INFO.AMOUNT,
      validators: [createMaxCharValidator(14), { validate: 'required' }],
      sizeHint: getColumnSize(Labels.AMOUNT),
    },
    {
      label: Labels.TPA_BD_PROCESSING_FEE,
      key: BD_TRSANCTION_INFO.TPA_BD_PROCESSING_FEE,
      validators: [createMaxCharValidator(14)],
      sizeHint: getColumnSize(Labels.TPA_BD_PROCESSING_FEE),
    },
    {
      label: Labels.INSPIRA_BD_PROCESSING_FEE,
      key: BD_TRSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE,
      validators: [{ validate: 'required' }, createMaxCharValidator(14)],
      sizeHint: getColumnSize(Labels.INSPIRA_BD_PROCESSING_FEE),
    },
    {
      label: Labels.DISTRIBUTION_AMOUNT,
      key: BD_TRSANCTION_INFO.DISTRIBUTION_AMOUNT,
      validators: [createMaxCharValidator(14), { validate: 'required' }],
      sizeHint: getColumnSize(Labels.DISTRIBUTION_AMOUNT + '    '), // "    " is added to the label to fully display the name in the column
    },
    {
      label: Labels.WIRE_ACH_1,
      key: BD_TRSANCTION_INFO.WIRE_ACH_1,
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.WIRE_ACH_1),
    },
    {
      label: Labels.ABA_ROUTING,
      key: BD_TRSANCTION_INFO.ABA_ROUTING,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^[0-9]{9}$',
          error:
            'Nine-digit number only, social security number (SSN) unaccepted',
        },
      ],
      sizeHint: getColumnSize(Labels.ABA_ROUTING),
    },
    {
      label: Labels.WIRE_ACH_2,
      key: BD_TRSANCTION_INFO.WIRE_ACH_2,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(?=.*[0-9]).+$',
          error: 'String must have at least one numeric character',
        },
      ],
      sizeHint: getColumnSize(Labels.WIRE_ACH_2),
    },
    {
      label: Labels.WIRE_ACH_3,
      key: BD_TRSANCTION_INFO.WIRE_ACH_3,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^[0-9]{9}$',
          error: 'Nine-digit number only',
        },
      ],
      sizeHint: getColumnSize(Labels.WIRE_ACH_3),
    },
    {
      label: Labels.FFC_NAME,
      key: BD_TRSANCTION_INFO.FFC_NAME,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^.*[A-Za-z].*$',
          error: 'String must have at least one alpha character',
        },
      ],
      sizeHint: getColumnSize(Labels.FFC_NAME),
    },
    {
      label: Labels.FFC_NUMBER,
      key: BD_TRSANCTION_INFO.FFC_NUMBER,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(?=.*[0-9]).+$',
          error: 'Must have at least one numeric character',
        },
      ],
      sizeHint: getColumnSize(Labels.FFC_NUMBER),
    },
    {
      label: Labels.ACCOUNT_TYPE,
      key: BD_TRSANCTION_INFO.ACCOUNT_TYPE,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(?:checking|savings)$',
          error: `Must be 'checking' or 'savings'`,
        },
      ],
      sizeHint: getColumnSize(Labels.ACCOUNT_TYPE),
    },
    {
      label: Labels.CHECK_PAYABLE_INFO,
      key: BD_TRSANCTION_INFO.CHECK_PAYABLE_INFO,
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.CHECK_PAYABLE_INFO),
    },
    {
      label: Labels.ACCOUNT_NUMBER,
      key: BD_TRSANCTION_INFO.ACCOUNT_NUMBER,
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(?=.*[0-9]).+$',
          error: 'Must have at least one numeric character',
        },
      ],
      sizeHint: getColumnSize(Labels.ACCOUNT_NUMBER),
    },
    {
      label: Labels.ADDRESS_FOR_CHECK,
      key: BD_TRSANCTION_INFO.ADDRESS_FOR_CHECK,
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.ADDRESS_FOR_CHECK),
    },
    {
      label: Labels.CITY_FOR_CHECK,
      key: BD_TRSANCTION_INFO.CITY_FOR_CHECK,
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.CITY_FOR_CHECK),
    },
    {
      label: Labels.STATE_FOR_CHECK,
      key: BD_TRSANCTION_INFO.STATE_FOR_CHECK,
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.STATE_FOR_CHECK),
    },
    {
      label: Labels.ZIP_FOR_CHECK,
      key: BD_TRSANCTION_INFO.ZIP_FOR_CHECK,
      validators: [createMaxCharValidator(10)],
      sizeHint: getColumnSize(Labels.ZIP_FOR_CHECK),
    },
  ],
  [RecordTypes.UCH_PARTICIPANT]: [
    {
      label: Labels.RECORDKEEPER,
      key: 'recordkeeper',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required. Enter your company name if self-administering.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Recordkeeper. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.RECORDKEEPER),
    },
    {
      label: Labels.THIRD_PARTY_ADMINISTRATOR,
      key: 'tpa',
      validators: [
        createMaxCharValidator(60),
        {
          validate: 'required',
          error: 'Required.',
        },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error:
            'Please enter a valid Third Party Administrator. No slashes allowed.',
        },
      ],
      sizeHint: getColumnSize(Labels.THIRD_PARTY_ADMINISTRATOR),
    },
    {
      label: Labels.PLAN_SPONSOR,
      key: 'planSponsor',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Sponsor. No slashes allowed.',
        },
        createMaxCharValidator(60),
      ],
      sizeHint: getColumnSize(Labels.PLAN_SPONSOR),
    },
    {
      label: Labels.COMPLETE_LEGAL_PLAN_NAME,
      key: 'planName',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: '\\/|\\\\',
          error: 'Please enter a valid Plan Name. No slashes allowed.',
        },
        createMaxCharValidator(80),
      ],
      sizeHint: getColumnSize(Labels.COMPLETE_LEGAL_PLAN_NAME),
    },
    {
      label: Labels.EMPLOYER_IDENTIFICATION_NUMBER,
      key: 'planEin',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_matches',
          regex: '^[0-9]{2}-[0-9]{7}$',
          error: 'Format must be: XX-XXXXXXX',
        },
        {
          validate: 'regex_matches',
          // valid EIN prefixes: https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes
          regex: '^(0[1-6]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|7[1-7]|9[0-589])',
          error: 'Invalid EIN',
        },
      ],
      sizeHint: getColumnSize(Labels.EMPLOYER_IDENTIFICATION_NUMBER),
    },
    {
      label: Labels.THREE_DIGIT_PLAN_NUMBER,
      key: 'planNumber',
      validators: [
        {
          validate: 'regex_excludes',
          regex: '^(\\D)',
          error: 'Invalid characters used',
        },
        {
          validate: 'regex_matches',
          regex: '^(\\d{3})$',
          error: 'Must be three digits',
        },
      ],
      sizeHint: getColumnSize(Labels.THREE_DIGIT_PLAN_NUMBER),
    },
    {
      label: Labels.PROVIDER_PLAN_ID_NUMBER,
      key: 'planIdNumber',
      validators: [createMaxCharValidator(30)],
      sizeHint: getColumnSize(Labels.PROVIDER_PLAN_ID_NUMBER),
    },
    {
      label: Labels.FIRST_NAME,
      key: 'firstName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(40),
        createNamePrefixValidator(),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.FIRST_NAME),
    },
    {
      label: Labels.MIDDLE_NAME,
      key: 'middleName',
      validators: [createMaxCharValidator(20)],
      sizeHint: getColumnSize(Labels.MIDDLE_NAME),
    },
    {
      label: Labels.LAST_NAME,
      key: 'lastName',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(60),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.LAST_NAME),
    },
    {
      label: Labels.ADDRESS_LINE_1,
      key: 'addressLine1',
      validators: [
        { validate: 'required' },
        createMaxCharValidator(36),
        createNotApplicableValidator(),
      ],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_1),
    },
    {
      label: Labels.ADDRESS_LINE_2,
      key: 'addressLine2',
      validators: [createMaxCharValidator(36)],
      sizeHint: getColumnSize(Labels.ADDRESS_LINE_2),
    },
    {
      label: Labels.CITY,
      key: 'city',
      validators: [createMaxCharValidator(22)],
      sizeHint: getColumnSize(Labels.CITY),
    },
    {
      label: Labels.STATE,
      key: 'state',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.STATE),
    },
    {
      label: Labels.ZIP_CODE,
      key: 'zipCode',
      validators: [],
      sizeHint: getColumnSize(Labels.ZIP_CODE),
    },
    {
      label: Labels.COUNTRY_CODE,
      key: 'countryCode',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.COUNTRY_CODE),
    },
    {
      label: Labels.DATE_OF_BIRTH,
      key: 'dob',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.DATE_OF_BIRTH),
    },
    {
      label: Labels.SSN,
      key: 'ssn',
      validators: [
        { validate: 'required' },
        {
          validate: 'regex_excludes',
          regex: additionalInvalidSsnRegex,
          error: 'Given SSN is not valid',
        },
      ],
      sizeHint: getColumnSize(Labels.SSN),
    },
    {
      label: Labels.EMAIL_ADDRESS,
      key: 'emailAddress',
      validators: [createMaxCharValidator(50)],
      sizeHint: getColumnSize(Labels.EMAIL_ADDRESS),
    },
    {
      label: Labels.TRADITIONAL_IRA_AMOUNT,
      key: IRA_AMOUNT.TRADITIONAL_IRA_AMOUNT,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.ROTH],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.TRADITIONAL_IRA_AMOUNT),
    },
    {
      label: Labels.ROTH_IRA_AMOUNT,
      key: IRA_AMOUNT.ROTH,
      validators: [
        createMaxCharValidator(14),
        {
          validate: 'required_without_all',
          fields: [IRA_AMOUNT.TRADITIONAL_IRA_AMOUNT],
          error: 'An IRA rollover amount is required',
        },
      ],
      sizeHint: getColumnSize(Labels.ROTH_IRA_AMOUNT),
    },
    {
      label: Labels.DATE_ORIGIONAL_DISTRIBUTION,
      key: 'dateOfOriginalDistribution',
      validators: [{ validate: 'required' }],
      sizeHint: getColumnSize(Labels.DATE_ORIGIONAL_DISTRIBUTION),
    },
    {
      label: Labels.TAX_WITHHELD,
      key: 'wasTaxWithheld',
      validators: [
        {
          validate: 'regex_matches',
          regex: '^(?:Y|N)$',
          error: 'Must be Y or N',
        },
      ],
      sizeHint: getColumnSize(Labels.TAX_WITHHELD),
    },
  ],
}

const ExcelFilesAllowed = {
  [RecordTypes.SEARCH]: false,
  [RecordTypes.ARO_PARTICIPANT]: true,
  [RecordTypes.BD_PARTICIPANT]: true,
  [RecordTypes.UCH_PARTICIPANT]: true,
}

const ManualInputDisabled = {
  [RecordTypes.SEARCH]: false,
  [RecordTypes.ARO_PARTICIPANT]: !isManualInputEnabled(),
  [RecordTypes.BD_PARTICIPANT]: false,
  [RecordTypes.UCH_PARTICIPANT]: !isManualInputEnabled(),
}

// same color as $primary-base defined in inspriaColors.scss
const defaultThemeColor = '#4553A9'

const flatFileUploadModalTitle = {
  [RecordTypes.SEARCH]: `Add search participants`,
  [RecordTypes.ARO_PARTICIPANT]: `Upload your participant information`,
  [RecordTypes.BD_PARTICIPANT]: `Upload your participant information`,
  [RecordTypes.UCH_PARTICIPANT]: `Upload your participant information`,
}

// getFlatfileConfig takes in an object called validators. It's used for fields with
// conditional validations that's dependent on state or values outside of flatfile
export const getFlatfileConfig = (recordType, validators = {}) => {
  const fields = isEmpty(validators)
    ? FlatfileFields[recordType]
    : cloneDeep(FlatfileFields[recordType])

  mapKeys(validators, (value, key) => {
    const field = find(fields, ['key', key])
    field.validators = value
  })
  return {
    type: `${RecordNames[recordType]} Record`,
    title: flatFileUploadModalTitle[recordType],
    i18nOverrides: {
      en: {
        otherLocales: ['en-US', 'en-CA', 'en-GB'],
        overrides: {
          manual: '',
          dropzone: {
            accepted: `Acceptable file types: ${join(
              AcceptableFileExtensions[recordType],
              ', '
            )}`,
            button: 'Upload data from file',
          },
          buttons: {
            review: 'Continue',
          },
          fileTypes: UploadInstructions[recordType],
          header2: 'Please review your data and correct any issues identified',
        },
      },
    },
    theme: {
      global: {
        successColor: defaultThemeColor,
      },
      progressBar: {
        complete: {
          color: defaultThemeColor,
        },
      },
      buttons: {
        primary: {
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        success: {
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        headerMatchYes: {
          color: 'white',
          backgroundColor: defaultThemeColor,
          border: `1px solid ${defaultThemeColor}`,
        },
        headerMatchNo: {
          color: defaultThemeColor,
          backgroundColor: 'transparent',
          border: `1px solid ${defaultThemeColor}`,
        },
      },
      headerMatch: {
        content: {
          color: defaultThemeColor,
        },
      },
      columnMatch: {
        content: {
          color: defaultThemeColor,
        },
      },
    },
    fields,
    managed: ExcelFilesAllowed[recordType],
    disableManualInput: ManualInputDisabled[recordType],
  }
}

export const getFlatfileSchema = (recordType, validators = {}) => {
  const fields = isEmpty(validators)
    ? FlatfileSchema[recordType]
    : cloneDeep(FlatfileSchema[recordType])

  mapKeys(validators, (value, key) => {
    const field = find(fields, ['key', key])
    field.validators = value
  })

  return {
    name: flatFileUploadModalTitle[recordType],
    sheets: [
      {
        name: `${RecordNames[recordType]} Record`,
        slug: `${RecordNames[recordType].toLowerCase()}-record`,
        fields,
      },
    ],
  }
}
