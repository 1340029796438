import { format, isValid, isBefore } from 'date-fns'
import { toNumber } from 'lodash'
import {
  formatCurrency,
  validateTaxId,
  parseDateFromString,
  findCountry,
  findUSState,
  convertToUppercase,
} from 'utils'
import * as yup from 'yup'
import { getFlatfileConfig, RecordTypes } from 'flatfile-config'
import { US_COUNTRY_VALUE } from 'config/locations'
import { invalidPostalCodes } from '../config/products'

const numericRegex = /[0-9]/g
const currencyRegex = /[0-9-.]/g
const NARegex = /^NA$/
const specialCharacterRegex =
  /[\u2018\u2019\u201A\u201B\u201C\u201D\u201E\u201F\u2032\u2033\u0022\u0060\u00B4\u0027]/g

const requiredError = [{ message: 'Required.', level: 'error' }]

// returns false if a value has invalid currency formatting (e.g., "10,00", "20.000", "30.00.00", "40.00,200")
// or if it doesn't contain any number (e.g., "apple", "")
const isValidAmount = (value) => {
  const sanitized = value.replaceAll(/[^0-9.,]/g, '')
  if (!sanitized) return false
  const currencyParts = sanitized.split('.')
  // If a decimal point exists, ensure that it only occurs at the end of the string and is followed by 1 or 2 digits
  if (
    currencyParts.length > 2 ||
    (currencyParts.length === 2 && !/^[0-9]{1,2}$/.test(currencyParts[1]))
  )
    return false
  // Check the value provided for proper separation (i.e., ignore the decimal)
  const [first, ...rest] = currencyParts[0].split(',')
  if (rest.length === 0) return true
  return first.length <= 3 && rest.every((v) => v.length === 3)
}

const formatAndValidateCurrency = (input) => {
  const sanitizedInput = input.match(currencyRegex)?.join('')
  const amount = toNumber(sanitizedInput)
  if (isNaN(amount) || !isValidAmount(input)) {
    return {
      value: input,
      info: [
        {
          message: 'Format must be: $X,XXX.XX',
          level: 'error',
        },
      ],
    }
  }
  return {
    value: formatCurrency(amount),
    info:
      amount < 0
        ? [
            {
              message: 'Amount cannot be negative',
              level: 'error',
            },
          ]
        : [],
  }
}

const flatfileConfig = getFlatfileConfig(RecordTypes.UCH_PARTICIPANT)
const fieldKeys = flatfileConfig.fields.map((field) => field.key)

function formatAndValidateUCHParticipantRecords(originalRecord) {
  /* convert string values to an object with 'value' -- necessary if values get modified or if a custom error message is provided (under 'info')
   if the record is missing any fields outlined in config, set its initial value as an empty string
  e.g., { firstName: "Jane", lastName: "Doe" } -> { firstName: { value: "Jane" }, lastName: { value: "Doe" }, middleName: {value: "" } } */
  let convertedRecord = fieldKeys.reduce((record, field) => {
    record[field] = { value: record[field]?.trim() ?? '' }
    return record
  }, originalRecord)

  const formattedRecord = formatRecord(convertedRecord)
  const formattedAndValidatedRecord = validateRecord(formattedRecord)

  return formattedAndValidatedRecord
}

const formatRecord = (record) => {
  let {
    planEin,
    planSponsor,
    planName,
    planIdNumber,
    planNumber,
    zipCode,
    dob,
    ssn,
    state,
    countryCode,
    traditionalIraAmount,
    rothIraAmount,
    dateOfOriginalDistribution,
    wasTaxWithheld,
  } = record

  if (planEin.value) {
    const sanitizedPlanEin = planEin.value.match(numericRegex)?.join('')
    // formats EIN to XX-XXXXXXX
    if (sanitizedPlanEin) {
      planEin.value = sanitizedPlanEin.replace(/(\d{2})(\d+)/, '$1-$2')
    }
  }
  if (planSponsor.value) {
    const sanitizedPlanSponsor = planSponsor.value
      .match(specialCharacterRegex)
      ?.join('')
    if (sanitizedPlanSponsor) {
      planSponsor.value = planSponsor.value.replace(specialCharacterRegex, "'")
    }
  }
  if (planName.value) {
    const sanitizedPlanName = planName.value
      .match(specialCharacterRegex)
      ?.join('')
    if (sanitizedPlanName) {
      planName.value = planName.value.replace(specialCharacterRegex, "'")
    }
  }
  if (planIdNumber.value) {
    planIdNumber.value = planIdNumber.value.replaceAll(/[^a-zA-Z0-9-]/g, '')
  }
  if (planNumber.value) {
    const sanitizedPlanNumber = planNumber.value.match(numericRegex)?.join('')
    if (sanitizedPlanNumber) {
      planNumber.value = sanitizedPlanNumber.replace(/(\d{3})/, '$1')
    }
  }
  if (zipCode.value) {
    zipCode.value = zipCode.value.replaceAll(/[^a-zA-Z0-9- ]/g, '')
    const sanitizedZip = zipCode.value.match(numericRegex)?.join('')
    if (sanitizedZip) {
      zipCode.value = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zipCode.value = '0' + zipCode.value
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zipCode.value = '00' + zipCode.value
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zipCode.value = '000' + zipCode.value
      }
    }
    if (sanitizedZip.length > 5) {
      zipCode.value =
        zipCode.value.substring(0, 5) + '-' + zipCode.value.substring(5)
    }
  }
  if (dob.value) {
    const dobDate = parseDateFromString(dob.value.trim())
    let formattedDob = dob.value

    if (dobDate) {
      formattedDob = format(dobDate, 'yyyy/MM/dd')
    } else {
      const sanitizedDob = dob.value.match(numericRegex)?.join('')
      if (sanitizedDob) {
        formattedDob = sanitizedDob.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
      }
    }
    dob.value = formattedDob
  }
  if (ssn.value) {
    const firstLetter = ssn.value.match(/(([\d]\D*?){9})([a-zA-Z])/)?.[3] || ''
    const sanitizedSsn = ssn.value.match(numericRegex)?.join('')
    // note: this approach concatenates numbers first: e.g., 111-22-3333A000 -> 111-22-3333000A
    if (sanitizedSsn) {
      ssn.value =
        sanitizedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') + firstLetter
    }
  }

  if (countryCode.value) {
    const matchingCountry = findCountry(countryCode.value)

    if (matchingCountry) {
      countryCode.value = matchingCountry.value
    } else {
      countryCode.info = [
        {
          message: 'Must be a valid country name or country code',
          level: 'error',
        },
      ]
    }
  }

  if (state.value && countryCode.value === US_COUNTRY_VALUE) {
    const matchingState = findUSState(state.value)
    if (matchingState) {
      state.value = matchingState.value
    } else {
      state.info = [{ message: 'Must be a valid US state', level: 'error' }]
    }
  }

  if (traditionalIraAmount.value) {
    record.traditionalIraAmount = formatAndValidateCurrency(
      traditionalIraAmount.value
    )
  }

  if (rothIraAmount.value) {
    record.rothIraAmount = formatAndValidateCurrency(rothIraAmount.value)
  }

  if (dateOfOriginalDistribution.value) {
    const dateofdistribution = parseDateFromString(
      dateOfOriginalDistribution.value.trim()
    )
    let formattedDofDistribution = dateOfOriginalDistribution.value

    if (dateofdistribution) {
      formattedDofDistribution = format(dateofdistribution, 'yyyy/MM/dd')
    } else {
      const sanitizedDofdistribution = dateOfOriginalDistribution.value
        .match(numericRegex)
        ?.join('')
      if (sanitizedDofdistribution) {
        formattedDofDistribution = sanitizedDofdistribution.replace(
          /(\d{4})(\d{2})(\d{2})/,
          '$1/$2/$3'
        )
      }
    }
    dateOfOriginalDistribution.value = formattedDofDistribution
  }

  if (wasTaxWithheld.value) {
    wasTaxWithheld.value = convertToUppercase(wasTaxWithheld.value)
  }

  return record
}

/* validation, especially presence validation, should be configured under 'fields' outlined in flatfile configuration-- 
 that way, if a file is missing required fields, flatfile would throw an error and prevent the user from proceeding to the "Review" step;
 However, some validations may be very complicated and not be achieved with flatfile validators. For such, use data hooks:  */
const validateRecord = (record) => {
  let {
    countryCode,
    city,
    state,
    zipCode,
    dob,
    ssn,
    emailAddress,
    rothIraAmount,
    traditionalIraAmount,
    dateOfOriginalDistribution,
    wasTaxWithheld,
  } = record

  if (countryCode.value === US_COUNTRY_VALUE) {
    if (zipCode.value) {
      const zipCodeRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
      const postalCode = zipCode.value.substring(0, 5)
      if (!zipCodeRe.test(zipCode.value)) {
        zipCode.info = [
          {
            message: 'US Zip Codes must have format: XXXXX or XXXXX-XXXX',
            level: 'error',
          },
        ]
      } else if (invalidPostalCodes.includes(postalCode)) {
        zipCode.info = [
          {
            message: 'Please enter a valid Zip Code',
            level: 'error',
          },
        ]
      }
    } else {
      // city and state are required if zip code is missing
      if (!city.value) city.info = requiredError
      if (!state.value) state.info = requiredError
      // zip code is required if both city and state are missing
      if (!city.value && !state.value) zipCode.info = requiredError
    }
  } else {
    if (state.value.length > 22) {
      state.info = [
        {
          message: 'Maximum length exceeded - must be 22 characters or less',
          level: 'error',
        },
      ]
    }
  }

  if (dob.value) {
    if (NARegex.test(dob.value)) {
      dob.info = [{ message: 'Required.', level: 'error' }]
    } else {
      const dobDate = new Date(dob.value)
      if (isValid(dobDate)) {
        const currentDate = new Date()
        const maxDate = new Date(currentDate)
        maxDate.setFullYear(currentDate.getFullYear() - 100)
        const minDate = new Date(currentDate)
        minDate.setFullYear(currentDate.getFullYear() - 10)
        if (dobDate > minDate) {
          dob.info = [{ message: 'Must be over 10 years.', level: 'error' }]
        } else {
          if (isBefore(dobDate, maxDate)) {
            dob.info = [{ message: 'Must be under 100 years.', level: 'error' }]
          }
        }
      } else {
        const dateRe = new RegExp('^[0-9]{4}/[0-9]{2}/[0-9]{2}$')
        dob.info = dateRe.test(dob.value)
          ? [
              {
                message: 'Must be a valid date', // e.g., "2000/22/44"
                level: 'error',
              },
            ]
          : [
              {
                message: 'Format must be yyyy/mm/dd',
                level: 'error',
              },
            ]
      }
    }
  }

  if (ssn.value) {
    if (NARegex.test(ssn.value)) {
      ssn.info = [{ message: 'Required.', level: 'error' }]
    } else {
      const ssnRe = new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$')
      if (!ssnRe.test(ssn.value)) {
        ssn.info = [
          {
            message: 'Format must be XXX-XX-XXXX',
            level: 'error',
          },
        ]
      } else if (!validateTaxId(ssn.value)) {
        ssn.info = [
          {
            message: 'Given SSN is not valid',
            level: 'error',
          },
        ]
      }
    }
  }

  if (emailAddress.value) {
    const schema = yup.string().email()
    const isValidEmail = schema.isValidSync(emailAddress.value)
    if (!isValidEmail) {
      emailAddress.info = [
        {
          message: 'Must be valid email format: example@email.com',
          level: 'error',
        },
      ]
    }
  }

  if (dateOfOriginalDistribution.value) {
    if (NARegex.test(dateOfOriginalDistribution.value)) {
      dateOfOriginalDistribution.info = [
        { message: 'Required.', level: 'error' },
      ]
    } else {
      const dateofDistributionDate = new Date(dateOfOriginalDistribution.value)
      if (isValid(dateofDistributionDate)) {
        const currentDate = new Date()
        const maxDate = new Date(currentDate)
        maxDate.setFullYear(currentDate.getFullYear() - 100)

        if (isBefore(dateofDistributionDate, maxDate)) {
          dateOfOriginalDistribution.info = [
            { message: 'Must be under 100 years.', level: 'error' },
          ]
        }
      } else {
        const dateRe = new RegExp('^[0-9]{4}/[0-9]{2}/[0-9]{2}$')
        dateOfOriginalDistribution.info = dateRe.test(
          dateOfOriginalDistribution.value
        )
          ? [
              {
                message: 'Must be a valid date', // e.g., "2000/22/44"
                level: 'error',
              },
            ]
          : [
              {
                message: 'Format must be yyyy/mm/dd',
                level: 'error',
              },
            ]
      }
    }
  }

  if (rothIraAmount.value || traditionalIraAmount.value) {
    if (!wasTaxWithheld.value) {
      wasTaxWithheld.info = [
        {
          message: 'Required.',
          level: 'error',
        },
      ]
    }
  }

  return record
}

export default formatAndValidateUCHParticipantRecords
