export const SearchOrderStatus = {
  IN_PROGRESS: 'In Progress',
  RESULTS_EXPIRED: 'Results Expired',
  FAILED: 'Failed',
  RESULTS_AVAILABLE: 'Results Available',
}
export const SearchTypeID = {
  ADDRESS_SEARCH: 'Address Search',
  CONFIRMED_SEARCH: 'Confirmed Search',
  BENEFICIARY_SEARCH: 'Beneficiary Search',
  FIDUCIARY_SEARCH: 'Fiduciary Search',
  ADVANCED_DEATH_AUDIT: 'Advanced Death Audit',
  RELATIVE_SEARCH: 'Relative Search',
}
export const SearchOrderOptions = {
  [SearchTypeID.ADDRESS_SEARCH]: { estimatedTime: 'Same Day' },
  [SearchTypeID.CONFIRMED_SEARCH]: { estimatedTime: 'X to Y business days' },
  [SearchTypeID.VERIFIED_DEATH_AUDIT]: {
    estimatedTime: 'X to Y business days',
  },
}
export const SEARCH_ORDER_PROHIBITED_COMPANIES = [
  'AAmva',
  'Acxiom',
  'Advo',
  'AECSoft',
  'Allant Group',
  'Alliance Data Systems',
  'Alliance Group Holdings',
  'Allied Interstate',
  'American Business Lists',
  'American List Counsel',
  'Anchor Computer',
  'Araxid',
  'Carfax',
  'Carproof',
  'Cavalry',
  'CDK Global',
  'CollectionIndustry.com',
  'Convergent',
  'Corelogic',
  'Cortera',
  'Cox Automotive',
  'Credit Control',
  'Credit Reform',
  'Credit Report SA',
  'CreditRiskMonitor.com',
  'Creditsafe',
  'Database 101',
  'DealerTrack',
  'Dominion',
  'Dunn and Bradstreet',
  'Encore',
  'Epsilon',
  'Equifax',
  'Exostar',
  'Experian',
  'Factiva',
  'Fair Isaac',
  'First Advantage',
  'First Data',
  'GC Services',
  'Green Dot',
  'HarteHanks',
  'HIS',
  'ID Analytics',
  'Idology',
  'InfoGROUP',
  'Innovis',
  'Insurance Services Office',
  'Intellaegis',
  'IQOR',
  'Jigsaw Data',
  'KnowledgeBase Marketing',
  'Leadsource',
  'LexisNexis',
  'LSSI',
  'Media 1',
  'Merkle',
  'National Association of Credit Management',
  'National Recovery',
  'Neustar',
  'NMVTIS',
  'Northland',
  'Phoenix Financial',
  'Pioneer',
  'Polk City Directories',
  'Portfolio Recovery',
  'R.L. Polk',
  'Receivable Management Services',
  'Reynolds Company',
  'Reynolds & Reynolds',
  'Reynolds Company (Reynolds & Reynolds)',
  'Riskwise',
  'RNN',
  'RSA',
  'Rust Consulting',
  'Southwest Credit Systems',
  'Target Base',
  'Targusinfo',
  'Thomson Reuters',
  'TransUnion',
  'TransUnion Risk and Alternative Data Solutions',
  'Verid',
  'Verisk Analytics',
  'Volt Delta',
  'West Publishing Group',
]
